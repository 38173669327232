import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { OuterContainer } from "../style";
import {
  getlabourlisting,
  refreshAdminListApi,
} from "../../services/Api/collection";
import { errorMessage } from "../../utils/commonMethod";
import TableComponent from "../../components/table";
import CustomePagination from "../../components/table/pagination";
import { useDispatch } from "react-redux";
import { load } from "../../services/redux/Loader";
//icons
import { IconButton } from "rsuite";
import "rsuite/dist/rsuite.min.css";

import { Reload } from "@rsuite/icons";

export const AdminManagment = () => {
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email_address",
      key: "email_address",
    },
    {
      title: "Country Code",
      dataIndex: "country_code",
      key: "country_code",
    },
    {
      title: "Phone Number(Mobile)",
      dataIndex: "mobile_phone",
      key: "mobile_phone",
    },
    {
      title: "Role",
      dataIndex: "job_title",
      key: "job_title",
    },
  ];
  const limit = 10;

  const [data, setdata] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalCount, setTotalCount] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const getlisting = async () => {
    setLoading(true);
    dispatch(load(true));
    const res = await getlabourlisting(currentPage, limit);
    console.log(res, "res");
    if (res?.status === 200) {
      setdata(res?.data?.labourData);
      setLoading(false);
      setTotalCount(res?.data?.totalCount);
      dispatch(load(false));
    } else {
      errorMessage(res);
      setLoading(false);
      dispatch(load(false));
    }
    setLoading(false);
    dispatch(load(false));
  };

  const handleRefresh = async () => {
    console.log("RefreshClicked");
    setLoading(true);
    dispatch(load(true));
    const res = await refreshAdminListApi(currentPage, limit);
    console.log(res, "res");
    if (res?.status === 200) {
      setdata(res?.data?.labourData);
      setLoading(false);
      setTotalCount(res?.data?.totalCount);
      dispatch(load(false));
    } else {
      errorMessage(res);
      setLoading(false);
      dispatch(load(false));
    }
    setLoading(false);
    dispatch(load(false));
  };

  const onPageChange = useCallback((page, pageSize) => {
    console.log(page, "page");
    setCurrentPage(page);
  }, []);
  useEffect(() => {
    getlisting();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  return (
    <EquipmentWrapper>
      <div className="heading-section">
       <p> Labour</p> 
        <IconButton
          icon={<Reload />}
          onClick={handleRefresh}
          color="orange"
          appearance="primary"
          className="refress-btn"
        />
      </div>

      <TableComponent data={data} columns={columns} loading={loading} />
      <CustomePagination
        current={currentPage}
        total={totalCount}
        onPageChange={onPageChange}
        defaultPageSize={limit}
      />
    </EquipmentWrapper>
  );
};

const EquipmentWrapper = styled(OuterContainer)`
.heading-section{
  display:flex;
  align-items:center;
  justify-content:space-between;
}
  
`;
