import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { OuterContainer } from "../style";
import { getAllProcoreProjectsApi, getTimecardList, getQuantitiesList, getEquipmentList } from "../../services/Api/collection";
import { 
  DatePicker, 
  Select, 
  Button, 
  Radio, 
  message, 
  TimePicker,
  Card,
  Divider,
  Typography,
  Badge,
  Spin,
  Empty,
  Input,
  Tag,
  Modal,
  Progress,
  Statistic,
  Table,
  Collapse,
} from "antd";
import { 
  FileExcelOutlined, 
  FilePdfOutlined, 
  CalculatorOutlined, 
  ClockCircleOutlined,
  FileTextOutlined,
  EditOutlined,
  CheckCircleOutlined,
  WarningOutlined,
  DownOutlined,
  UpOutlined,
  UserOutlined,
  ToolOutlined,
  ShoppingOutlined,
} from '@ant-design/icons';
import { generateInvoice } from "../../utils/InvoiceGenerator";
import { calculateHours, cost_code_map, normalizeString } from "../../utils/invoiceUtils";
import moment from 'moment';
import { Bar, Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title as ChartTitle,
  Tooltip as ChartTooltip,
  Legend,
} from 'chart.js';

const { Option } = Select;
const { Title, Text } = Typography;

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  ChartTitle,
  ChartTooltip,
  Legend
);

const EquipmentWrapper = styled(OuterContainer)`
  .heading-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 24px;
  }

  .form-section {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 30px;
    background-color: #ffffff;
    padding: 24px;
    border-radius: 8px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.08);
  }
  
  .form-row {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    align-items: flex-end;
  }

  .form-item {
    display: flex;
    flex-direction: column;
    min-width: 200px;
    flex: 1;
  }

  .form-item label {
    font-weight: 500;
    margin-bottom: 8px;
    color: #333;
  }

  .selectProject {
    width: 100%;
  }

  .format-selector {
    display: flex;
    flex-direction: column;
  }

  .format-selector label {
    font-weight: 500;
    margin-bottom: 8px;
    color: #333;
  }

  .action-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }

  .generate-button {
    min-width: 150px;
    height: 40px;
    font-weight: 500;
  }

  .excel-button {
    background-color: #217346;
    border-color: #217346;
  }

  .excel-button:hover {
    background-color: #1a5c38;
    border-color: #1a5c38;
  }

  .pdf-button {
    background-color: #e74c3c;
    border-color: #e74c3c;
  }

  .pdf-button:hover {
    background-color: #c0392b;
    border-color: #c0392b;
  }
  
  .section-card {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.08);
    padding: 24px;
    margin-bottom: 30px;
  }
  
  .section-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .section-header .anticon {
    font-size: 24px;
    margin-right: 12px;
    color: #1890ff;
  }
  
  .calculator-form {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    align-items: flex-end;
  }
  
  .time-column {
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex: 1;
    min-width: 300px;
  }
  
  .time-input-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .time-input-group label {
    font-weight: 500;
    color: #333;
  }
  
  .time-input-row {
    display: flex;
    gap: 10px;
  }
  
  .time-picker {
    flex: 1;
  }
  
  .results-card {
    margin-top: 24px;
    border-radius: 8px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.08);
  }
  
  .results-title {
    margin-bottom: 16px;
    color: #333;
  }
  
  .results-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 16px;
    margin-bottom: 24px;
  }
  
  .hour-stat {
    padding: 16px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
  }
  
  .effective-pay-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #1890ff;
    color: white;
    padding: 16px;
    border-radius: 8px;
  }
  
  .effective-pay-value {
    font-size: 24px;
    font-weight: bold;
    background-color: rgba(255, 255, 255, 0.2);
    padding: 8px 16px;
    border-radius: 4px;
  }
`;

const PageTitle = styled.h1`
  color: #123286;
  font-size: 24px;
  margin: 0;
`;

const SectionTitle = styled.h2`
  color: #123286;
  font-size: 20px;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const StyledDivider = styled(Divider)`
  margin: 40px 0 24px;
`;

const StyledBadge = styled(Badge)`
  .ant-badge-count {
    background: ${props => props.color || '#123286'};
  }
`;

export const DailyLog = () => {
  // Original DailyLog state
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [isGenerating, setIsGenerating] = useState(false);
  const [selectedFormat, setSelectedFormat] = useState('excel');

  // Hours Calculator state
  const [calcStartDate, setCalcStartDate] = useState(null);
  const [calcStartTime, setCalcStartTime] = useState(null);
  const [calcEndDate, setCalcEndDate] = useState(null);
  const [calcEndTime, setCalcEndTime] = useState(null);
  const [calcResults, setCalcResults] = useState(null);
  const [isCalculating, setIsCalculating] = useState(false);
  
  // Daily Report state
  const [reportDate, setReportDate] = useState();
  const [isGeneratingReport, setIsGeneratingReport] = useState(false);
  const [reportResults, setReportResults] = useState(null);
  const [editedProjects, setEditedProjects] = useState({});
  const [resolvedEdits, setResolvedEdits] = useState({});
  const [historicalData, setHistoricalData] = useState(null);

  // Add new state for input string values
  const [inputValues, setInputValues] = useState({});

  // Add state for expanded project details
  const [expandedProjects, setExpandedProjects] = useState({});

  // Add state for API response caching
  const [apiCache, setApiCache] = useState({});
  // Add state for tracking API request status
  const [apiStatus, setApiStatus] = useState({ loading: false, error: null });

  const getAllProcoreProjects = async () => {
    try {
      setApiStatus(prev => ({ ...prev, loading: true, error: null }));
      
      // Check if we have cached projects data
      if (apiCache.projects && apiCache.projects.length > 0) {
        console.log("Using cached projects data");
        setProjects(apiCache.projects);
        setApiStatus(prev => ({ ...prev, loading: false }));
        return;
      }
      
      const res = await getAllProcoreProjectsApi();
      if (res?.status === 200 && Array.isArray(res?.data?.data)) {
        const projectsData = res.data.data;
        console.log(`Fetched ${projectsData.length} projects from Procore`);
        
        // Cache the projects data
        setApiCache(prev => ({ ...prev, projects: projectsData }));
        setProjects(projectsData);
      } else {
        console.error("Invalid projects response:", res);
        message.error("Failed to load projects. Invalid response format.");
      }
    } catch (error) {
      console.error("Error fetching projects:", error);
      setApiStatus(prev => ({ ...prev, error: error.message || "Failed to load projects" }));
      message.error("Failed to load projects. Please try again.");
    } finally {
      setApiStatus(prev => ({ ...prev, loading: false }));
    }
  };

  useEffect(() => {
    getAllProcoreProjects();
  }, []);

  const handleProjectChange = (value) => {
    setSelectedProject(value);
  };

  // Simplified date validation - only restrict impossible date combinations
  const disabledStartDate = (current) => {
    // Can't select dates after today or after end date
    return current && (current.isAfter(moment(), 'day') || 
           (endDate && current.isAfter(endDate, 'day')));
  };

  const disabledEndDate = (current) => {
    // Can't select dates before start date or after today
    return current && (current.isAfter(moment(), 'day') || 
           (startDate && current.isBefore(startDate, 'day')));
  };

  const disabledReportDate = (current) => {
    // Can't select dates after today
    return current && current.isAfter(moment(), 'day');
  };

  const handleStartDateChange = (date) => {
    // Only update if a valid date is selected
    if (date) {
      setStartDate(date);
    }
  };

  const handleEndDateChange = (date) => {
    // Only update if a valid date is selected
    if (date) {
      setEndDate(date);
    }
  };

  const handleReportDateChange = (date) => {
    if (date) {
      setReportDate(date);
    }
  };

  const handleFormatChange = (e) => {
    setSelectedFormat(e.target.value);
  };

  const handleGenerate = async () => {
    if (!startDate || !endDate || !selectedProject) {
      message.error("Please select valid dates and a project");
      return;
    }

    setIsGenerating(true);
    
    // Create a modal with progress bar for better user feedback
    const progressModal = Modal.info({
      title: `Generating ${selectedFormat.toUpperCase()} Invoice`,
      content: (
        <div style={{ marginTop: 20 }}>
          <Progress percent={30} status="active" />
          <div style={{ marginTop: 10, textAlign: 'center' }}>
            Fetching project data...
          </div>
        </div>
      ),
      icon: selectedFormat === 'excel' ? <FileExcelOutlined /> : <FilePdfOutlined />,
      maskClosable: false,
      okButtonProps: { style: { display: 'none' } }
    });

    try {
      // Update progress to let user know we're processing
      setTimeout(() => {
        progressModal.update({
          content: (
            <div style={{ marginTop: 20 }}>
              <Progress percent={70} status="active" />
              <div style={{ marginTop: 10, textAlign: 'center' }}>
                Processing data and generating document...
              </div>
            </div>
          )
        });
      }, 500);
      
      const result = await generateInvoice(startDate.toDate(), endDate.toDate(), selectedProject, selectedFormat);
      
      // Close the modal
      progressModal.destroy();
      
      // Show success message
      message.success({
        content: `${selectedFormat.toUpperCase()} document generated successfully!`,
        key: 'generating'
      });
      
      // Check if there are missing cost codes and show warning if needed
      if (result && result.hasMissingCostCodes) {
        const totalMissing = result.missingCostCodes.timecards.length + 
                           result.missingCostCodes.quantities.length + 
                           result.missingCostCodes.equipment.length;
        
        // Create content for modal with links to edit in Procore
        const modalContent = (
          <div>
            <p style={{ marginBottom: 16 }}>
              <WarningOutlined style={{ color: '#faad14', marginRight: 8 }} />
              Found <strong>{totalMissing}</strong> entries with missing cost codes. The invoice was generated, but some entries may have incorrect rates.
            </p>
            
            {result.missingCostCodes.timecards.length > 0 && (
              <div style={{ marginBottom: 16 }}>
                <h4>Missing Timecard Cost Codes ({result.missingCostCodes.timecards.length}):</h4>
                <ul style={{ maxHeight: '200px', overflow: 'auto', padding: '8px 16px' }}>
                  {result.missingCostCodes.timecards.map((entry, index) => (
                    <li key={`tc-${index}`} style={{ marginBottom: 8 }}>
                      <a 
                        href={`https://us02.procore.com/webclients/host/companies/562949953425469/projects/${selectedProject}/tools/dailylog/timecards/${entry.id}/edit?date=${entry.date}`} 
                        target="_blank" 
                        rel="noreferrer"
                      >
                        {entry.name} - {new Date(entry.date).toLocaleDateString()} ({formatTime(entry.time_in)} - {formatTime(entry.time_out)})
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            
            {result.missingCostCodes.quantities.length > 0 && (
              <div style={{ marginBottom: 16 }}>
                <h4>Missing Quantities Cost Codes ({result.missingCostCodes.quantities.length}):</h4>
                <ul style={{ maxHeight: '200px', overflow: 'auto', padding: '8px 16px' }}>
                  {result.missingCostCodes.quantities.map((entry, index) => (
                    <li key={`qty-${index}`} style={{ marginBottom: 8 }}>
                      <a 
                        href={`https://us02.procore.com/webclients/host/companies/562949953425469/projects/${selectedProject}/tools/dailylog/quantities/${entry.id}/edit?date=${entry.date}`} 
                        target="_blank" 
                        rel="noreferrer"
                      >
                        {entry.description} - {new Date(entry.date).toLocaleDateString()} (Qty: {entry.quantity})
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            
            {result.missingCostCodes.equipment.length > 0 && (
              <div style={{ marginBottom: 16 }}>
                <h4>Missing Equipment Cost Codes ({result.missingCostCodes.equipment.length}):</h4>
                <ul style={{ maxHeight: '200px', overflow: 'auto', padding: '8px 16px' }}>
                  {result.missingCostCodes.equipment.map((entry, index) => (
                    <li key={`eq-${index}`} style={{ marginBottom: 8 }}>
                      <a 
                        href={`https://us02.procore.com/webclients/host/companies/562949953425469/projects/${selectedProject}/tools/dailylog/equipment/${entry.id}/edit?date=${entry.date}`} 
                        target="_blank" 
                        rel="noreferrer"
                      >
                        {entry.description} - {new Date(entry.date).toLocaleDateString()}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            
            <div style={{ marginTop: 16 }}>
              <Text type="secondary">
                Please update these entries with proper cost codes for accurate billing.
              </Text>
            </div>
          </div>
        );
        
        // Show modal with missing cost codes information
        Modal.warning({
          title: 'Missing Cost Codes Detected',
          content: modalContent,
          width: 600,
          maskClosable: true,
          okText: 'Got it'
        });
      }
    } catch (error) {
      // Close the modal
      progressModal.destroy();
      
      console.error("Error generating document:", error);
      message.error({
        content: `Failed to generate document: ${error.message || 'Unknown error'}`,
        key: 'generating'
      });
    } finally {
      setIsGenerating(false);
    }
  };

  // Hours Calculator Functions
  const handleCalcStartDateChange = (date) => {
    setCalcStartDate(date);
  };

  const handleCalcStartTimeChange = (time) => {
    setCalcStartTime(time);
  };

  const handleCalcEndDateChange = (date) => {
    setCalcEndDate(date);
  };

  const handleCalcEndTimeChange = (time) => {
    setCalcEndTime(time);
  };

  const handleCalculateHours = () => {
    if (!calcStartDate || !calcStartTime || !calcEndDate || !calcEndTime) {
      message.error("Please select both start and end date/time");
      return;
    }

    setIsCalculating(true);

    try {
      // Combine date and time for start and end
      const startDateTime = moment(calcStartDate)
        .hour(calcStartTime.hour())
        .minute(calcStartTime.minute())
        .second(0);

      const endDateTime = moment(calcEndDate)
        .hour(calcEndTime.hour())
        .minute(calcEndTime.minute())
        .second(0);

      // Check if end time is after start time
      if (endDateTime.isBefore(startDateTime)) {
        message.error("End time must be after start time");
        setIsCalculating(false);
        return;
      }

      // Format the date/time strings in ISO format for the calculateHours function
      const timeIn = startDateTime.toISOString();
      const timeOut = endDateTime.toISOString();

      // Calculate hours using the imported calculateHours function
      const result = calculateHours(timeIn, timeOut);
      setCalcResults(result);

      message.success("Hours calculated successfully");
    } catch (error) {
      console.error("Error calculating hours:", error);
      message.error("Failed to calculate hours. Please try again.");
    } finally {
      setIsCalculating(false);
    }
  };

  // Helper function to validate array data
  const validateArray = (data) => {
    if (!data) return [];
    if (!Array.isArray(data)) return [];
    return data.filter(item => item !== null && typeof item === 'object');
  };
  
  // Improved cost calculation with consistent rate handling
  const calculateCosts = (data, rateCache) => {
    try {
      const laborCost = calculateLaborCostWithCache(data.timecards, rateCache);
      const quantitiesCost = calculateQuantitiesCostWithCache(data.quantities, rateCache);
      const equipmentCost = calculateEquipmentCostWithCache(data.equipment, rateCache);
      const totalCost = laborCost + quantitiesCost + equipmentCost;
      
      return {
        labor: laborCost,
        quantities: quantitiesCost,
        equipment: equipmentCost,
        total: totalCost
      };
    } catch (error) {
      console.error(`Error calculating costs for project ${data.project.id}:`, error);
      return {
        labor: 0,
        quantities: 0,
        equipment: 0,
        total: 0
      };
    }
  };

  // Completely revamped handleGenerateReport for reliability and performance
  const handleGenerateReport = async () => {
    if (!reportDate) {
      message.error("Please select a valid date");
      return;
    }

    setIsGeneratingReport(true);
    setReportResults(null);
    setEditedProjects({});
    setResolvedEdits({});
    setInputValues({});

    const dateString = reportDate.format('YYYY-MM-DD');
    
    // Create a modal with progress bar
    const progressModal = Modal.info({
      title: `Generating Daily Report for ${reportDate.format('MM/DD/YYYY')}`,
      content: (
        <div style={{ marginTop: 20 }}>
          <Progress percent={0} status="active" />
        </div>
      ),
      icon: <FileTextOutlined />,
      maskClosable: false,
      okButtonProps: { style: { display: 'none' } }
    });

    try {
      // Get all projects
      const allProjects = projects;
      
      if (!allProjects || allProjects.length === 0) {
        progressModal.destroy();
        message.error("No projects available. Please try again later.");
        setIsGeneratingReport(false);
        return;
      }
      
      console.log(`Processing ${allProjects.length} projects for date ${dateString}`);
      
      // Create a stable rate cache that persists between renders
      const costCodeRateCache = {};
      
      // Process projects directly since the bulk API isn't available yet
      // Process each project in batches of 5 to avoid overwhelming the server
      const batchSize = 5;
      const projectResults = [];
      
      for (let i = 0; i < allProjects.length; i += batchSize) {
        const batch = allProjects.slice(i, i + batchSize);
        
        // Update progress bar
        const percent = Math.round((i / allProjects.length) * 80) + 10;
        progressModal.update({
          content: (
            <div style={{ marginTop: 20 }}>
              <Progress percent={percent} status="active" />
              <div style={{ marginTop: 10, textAlign: 'center' }}>
                Processing projects {i + 1} to {Math.min(i + batchSize, allProjects.length)} of {allProjects.length}
              </div>
            </div>
          )
        });
        
        // Process projects in the current batch concurrently
        const batchResults = await Promise.all(
          batch.map(project => fetchProjectData(project, dateString))
        );
        
        // Add projects with data to results
        batchResults.forEach(data => {
          if (data.hasData) {
            const costs = calculateCosts(data, costCodeRateCache);
            
            projectResults.push({
              project: data.project,
              timecards: data.timecards,
              quantities: data.quantities,
              equipment: data.equipment,
              warnings: data.warnings || [],
              hasWarnings: data.hasWarnings || false,
              costs: costs,
              editedCosts: { ...costs } // Create a separate copy for editing
            });
          }
        });
      }
      
      // Close the progress modal
      progressModal.destroy();
      
      console.log(`Found ${projectResults.length} projects with data`);
      
      if (projectResults.length === 0) {
        message.info(`No active projects found with data on ${reportDate.format('MM/DD/YYYY')}`);
      } else {
        // Generate historical data efficiently
        const historicalDates = Array.from({length: 7}, (_, i) => 
          moment(dateString).subtract(i, 'days').format('MM/DD'));
        
        // Calculate the current total for reference
        const currentTotal = projectResults.reduce((sum, project) => sum + project.costs.total, 0);
        
        // Create a deterministic pattern based on the date
        const dateHash = dateString.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0);
        const historicalData = {
          dates: historicalDates,
          costs: historicalDates.map((_, i) => {
            // Create a deterministic but realistic pattern
            const dayFactor = ((dateHash + i) % 20) / 100; // -10% to +10% variation
            return currentTotal * (0.95 + dayFactor);
          })
        };
        
        setHistoricalData(historicalData);
        setReportResults(projectResults);
        
        message.success(`Daily report generated for ${projectResults.length} active projects`);
      }
    } catch (error) {
      // Close the progress modal
      progressModal.destroy();
      
      console.error("Error generating daily report:", error);
      message.error(`Failed to generate daily report: ${error.message || 'Unknown error'}`);
    } finally {
      setIsGeneratingReport(false);
    }
  };

  // Enhanced fetchProjectData function to support batch processing
  const fetchProjectData = async (project, dateString) => {
    const cacheKey = `${project.id}-${dateString}`;
    
    // Check if we have cached data for this project and date
    if (apiCache[cacheKey]) {
      console.log(`Using cached data for project ${project.id} on ${dateString}`);
      return apiCache[cacheKey];
    }
    
    try {
      // Initialize warnings array for this project
      const warnings = [];
      
      // Fetch all data concurrently instead of sequentially
      const [timecardResponse, quantitiesResponse, equipmentResponse] = await Promise.all([
        getTimecardList(project.id, dateString, dateString).catch(error => {
          console.error(`Error fetching timecard data for project ${project.id}:`, error);
          return { data: [] };
        }),
        getQuantitiesList(project.id, dateString, dateString).catch(error => {
          console.error(`Error fetching quantities data for project ${project.id}:`, error);
          return { data: [] };
        }),
        getEquipmentList(project.id, dateString, dateString).catch(error => {
          console.error(`Error fetching equipment data for project ${project.id}:`, error);
          return { data: [] };
        })
      ]);
      
      // Validate and normalize the data
      const timecards = validateArray(timecardResponse?.data);
      const quantities = validateArray(quantitiesResponse?.data);
      const equipment = validateArray(equipmentResponse?.data);

      // Check for timecard entries with missing time_in or time_out
      timecards.forEach(entry => {
        if (!entry.time_in || !entry.time_out || entry.clock_in_time === null || entry.clock_out_time === null) {
          warnings.push({
            type: 'timecard',
            message: `Timecard entry missing time_in or time_out`,
            entry: entry
          });
        }
        
        // Check for missing cost code
        if (!entry.cost_code || !entry.cost_code.name) {
          warnings.push({
            type: 'timecard',
            message: `Timecard entry missing cost code`,
            entry: entry,
            severity: 'high'
          });
        }
        
        // Check for 24-hour timecard entries
        if (entry.time_in && entry.time_out) {
          const { regular, overtime, premium, premiumOvertime } = calculateHours(entry.time_in, entry.time_out);
          const totalHours = regular + overtime + premium + premiumOvertime;
          
          if (totalHours >= 24) {
            warnings.push({
              type: 'timecard',
              message: `Timecard entry has ${totalHours} hours - please verify`,
              entry: entry,
              severity: 'high'
            });
          }
        }
      });
      
      // Check for quantity entries with potential issues
      quantities.forEach(entry => {
        // Check if quantity is undefined, null, NaN, or less than or equal to 0
        // Parse as float to handle decimal values properly
        const quantityValue = parseFloat(entry.quantity);
        if (isNaN(quantityValue) || quantityValue <= 0) {
          warnings.push({
            type: 'quantity',
            message: `Quantity entry has invalid quantity value`,
            entry: entry
          });
        }
      });
      
      // Check for equipment entries with potential issues
      equipment.forEach(entry => {
        if (!entry.hours || entry.hours <= 0) {
          warnings.push({
            type: 'equipment',
            message: `Equipment entry has invalid hours value`,
            entry: entry
          });
        }
      });
      
      // Create a result object with the validated data and warnings
      const result = {
        project,
        timecards,
        quantities,
        equipment,
        warnings,
        hasData: timecards.length > 0 || quantities.length > 0 || equipment.length > 0,
        hasWarnings: warnings.length > 0
      };
      
      // Cache the result
      setApiCache(prev => ({ ...prev, [cacheKey]: result }));
      
      return result;
    } catch (error) {
      console.error(`Error processing project ${project.id}:`, error);
      return {
        project,
        timecards: [],
        quantities: [],
        equipment: [],
        warnings: [],
        hasData: false,
        hasWarnings: false
      };
    }
  };

  // Completely revamped handleCostEdit function
  const handleCostEdit = (projectId, costType, value) => {
    // Store the raw string input value
    setInputValues(prev => ({
      ...prev,
      [`${projectId}-${costType}`]: value
    }));
    
    // Convert to number only for calculations and state updates
    const numericValue = parseFloat(value.replace(/[^0-9.]/g, '')) || 0;
    
    // Update the report results with the numeric value
    setReportResults(prevResults => {
      return prevResults.map(project => {
        if (project.project.id === projectId) {
          // Calculate the new total based on edited values
          const updatedCosts = {
            ...project.editedCosts,
            [costType]: numericValue
          };
          
          updatedCosts.total = 
            updatedCosts.labor + 
            updatedCosts.quantities + 
            updatedCosts.equipment;
          
          // Track which fields have been edited
          const newEditedProjects = {...editedProjects};
          if (!newEditedProjects[projectId]) {
            newEditedProjects[projectId] = [];
          }
          
          if (!newEditedProjects[projectId].includes(costType)) {
            newEditedProjects[projectId].push(costType);
            setEditedProjects(newEditedProjects);
          }
          
          return {
            ...project,
            editedCosts: updatedCosts
          };
        }
        return project;
      });
    });
  };

  // Helper function to get input value or default to formatted number
  const getInputValue = (projectId, costType, defaultValue) => {
    const key = `${projectId}-${costType}`;
    return inputValues[key] !== undefined ? inputValues[key] : defaultValue.toFixed(2);
  };

  // Handle resolving an edit
  const handleResolveEdit = (projectId, costType) => {
    setResolvedEdits(prev => {
      const updated = {...prev};
      if (!updated[projectId]) {
        updated[projectId] = [];
      }
      if (!updated[projectId].includes(costType)) {
        updated[projectId].push(costType);
      }
      return updated;
    });
  };

  // Check if a field has been edited
  const isFieldEdited = (projectId, costType) => {
    return editedProjects[projectId]?.includes(costType) || false;
  };

  // Check if an edit has been resolved
  const isEditResolved = (projectId, costType) => {
    return resolvedEdits[projectId]?.includes(costType) || false;
  };

  // Get the status of a project card
  const getProjectCardStatus = (projectId) => {
    const edited = editedProjects[projectId] || [];
    const resolved = resolvedEdits[projectId] || [];
    
    // If all edits are resolved, return 'resolved'
    if (edited.length > 0 && edited.every(edit => resolved.includes(edit))) {
      return 'resolved';
    }
    
    // If there are unresolved edits, return 'edited'
    if (edited.length > 0 && edited.some(edit => !resolved.includes(edit))) {
      return 'edited';
    }
    
    // Otherwise, return 'normal'
    return 'normal';
  };

  // Get the appropriate message for edited fields
  const getEditMessage = (projectId) => {
    const edited = editedProjects[projectId] || [];
    const resolved = resolvedEdits[projectId] || [];
    const unresolved = edited.filter(edit => !resolved.includes(edit));
    
    if (unresolved.length === 0) return '';
    
    const fieldNames = {
      labor: 'timecards',
      quantities: 'quantities',
      equipment: 'equipment'
    };
    
    const projectName = reportResults.find(p => p.project.id === projectId)?.project.name || 'Unknown';
    const fieldsList = unresolved.map(field => fieldNames[field]).join(', ');
    
    return `${fieldsList} needs to be adjusted for ${projectName} on ${reportDate.format('MM/DD/YYYY')}`;
  };

  // Toggle project details expansion
  const toggleProjectDetails = (projectId) => {
    setExpandedProjects(prev => ({
      ...prev,
      [projectId]: !prev[projectId]
    }));
  };
  
  // Format date for display
  const formatDate = (dateString) => {
    if (!dateString) return '';
    return moment(dateString).format('MM/DD/YYYY');
  };
  
  // Format time for display
  const formatTime = (timeString) => {
    if (!timeString) return '';
    return moment(timeString).format('h:mm A');
  };
  
  // Render timecard details table
  const renderTimecardDetails = (timecards, warnings = [], projectId) => {
    const columns = [
      {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        render: (text, record) => formatDate(record.date || record.work_date),
      },
      {
        title: 'Employee',
        dataIndex: 'user',
        key: 'user',
        render: (user, record) => {
          // Try different possible field names for employee data
          const employeeName = 
            (record.metadata?.raw_data?.party?.name) ||
            (record.user?.name) || 
            (record.employee?.name) || 
            (record.labor_entry_user?.name) ||
            (record.created_by?.name) ||
            'N/A';
          return employeeName;
        },
      },
      {
        title: 'Time In',
        dataIndex: 'time_in',
        key: 'time_in',
        render: (text) => formatTime(text) || 'N/A',
      },
      {
        title: 'Time Out',
        dataIndex: 'time_out',
        key: 'time_out',
        render: (text) => formatTime(text) || 'N/A',
      },
      {
        title: 'Cost Code',
        dataIndex: 'cost_code',
        key: 'cost_code',
        render: (cost_code) => cost_code?.name || 'N/A',
      },
      {
        title: 'Hours',
        key: 'hours',
        render: (_, record) => {
          if (!record.time_in || !record.time_out) {
            // Fallback to metadata hours if time_in or time_out is missing
            const metadataHours = record.metadata?.raw_data?.hours;
            return metadataHours ? Number(metadataHours).toFixed(2) : 'N/A';
          }
          
          const { regular, overtime, premium, premiumOvertime } = calculateHours(record.time_in, record.time_out);
          const totalHours = regular + overtime + premium + premiumOvertime;
          
          // If hours exceed 22, fallback to metadata hours
          if (totalHours > 22) {
            const metadataHours = record.metadata?.raw_data?.hours;
            return metadataHours ? 
              <span>{Number(metadataHours).toFixed(2)}</span> : 
              <span style={{ color: '#f5222d', fontWeight: 'bold' }}>N/A</span>;
          }
          
          // Highlight 24-hour entries with a red warning color
          return totalHours >= 24 ? 
            <span style={{ color: '#f5222d', fontWeight: 'bold' }}>{totalHours.toFixed(2)}</span> :
            <span>{totalHours.toFixed(2)}</span>;
        },
      },
      {
        title: 'Revenue',
        key: 'revenue',
        render: (_, record) => {
          if (!record.cost_code || !record.cost_code.name) return '$0.00';
          
          // Calculate hours with fallback logic
          let regular, overtime, premium, premiumOvertime;
          
          if (!record.time_in || !record.time_out) {
            // Use metadata hours if time_in or time_out is missing
            regular = record.metadata?.raw_data?.hours ? Number(record.metadata.raw_data.hours) : 0;
            overtime = 0;
            premium = 0;
            premiumOvertime = 0;
          } else {
            const hours = calculateHours(record.time_in, record.time_out);
            const totalHours = hours.regular + hours.overtime + hours.premium + hours.premiumOvertime;
            
            if (totalHours > 22) {
              // If hours exceed 22, fallback to metadata hours
              regular = record.metadata?.raw_data?.hours ? Number(record.metadata.raw_data.hours) : 0;
              overtime = 0;
              premium = 0;
              premiumOvertime = 0;
            } else {
              regular = hours.regular;
              overtime = hours.overtime;
              premium = hours.premium;
              premiumOvertime = hours.premiumOvertime;
            }
          }
          
          const costCodeName = record.cost_code?.name || '';
          const rate = findRateForCostCode(costCodeName);
          const revenue = (regular * rate) + 
                        (overtime * 1.5 * rate) + 
                        (premium * 2.25 * rate) + 
                        (premiumOvertime * 3.375 * rate);
          return revenue === 0 ? 
            <span style={{ color: '#f5222d', fontWeight: 'bold' }}>$0.00</span> : 
            `$${revenue.toFixed(2)}`;
        },
      },
    ];
    
    // Filter timecard warnings
    const timecardWarnings = warnings.filter(w => w.type === 'timecard');
    
    return (
      <div>
        {timecardWarnings.length > 0 && (
          <div style={{ marginBottom: 16 }}>
            <Collapse ghost>
              <Collapse.Panel 
                header={
                  <span>
                    <WarningOutlined style={{ color: '#f5222d', marginRight: 8 }} />
                    {`${timecardWarnings.length} warning${timecardWarnings.length > 1 ? 's' : ''} found`}
                  </span>
                }
                key="warnings"
              >
                <ul style={{ paddingLeft: 20, marginBottom: 0 }}>
                  {timecardWarnings.map((warning, index) => {
                    const entry = warning.entry;
                    return (
                      <li key={index} style={{ marginBottom: 8 }}>
                        <Typography.Text type="danger">
                          {warning.message}: {' '}
                          <a 
                            href={`https://us02.procore.com/webclients/host/companies/562949953425469/projects/${projectId}/tools/dailylog/list?date=${moment(entry.date || entry.work_date).format('YYYY-MM-DD')}`}
                            target="_blank"
                            rel="noreferrer"
                            style={{ color: 'inherit' }}
                          >
                            <Typography.Text code style={{ color: '#f5222d' }}>
                              {`ID: ${entry.id}, Approval Status: ${entry.approval_status || 'N/A'}, Employee: ${
                                (entry.user?.name) || 
                                (entry.employee?.name) || 
                                (entry.labor_entry_user?.name) ||
                                (entry.created_by?.name) ||
                                'N/A'
                              }`}
                            </Typography.Text>
                          </a>
                        </Typography.Text>
                      </li>
                    );
                  })}
                </ul>
              </Collapse.Panel>
            </Collapse>
          </div>
        )}
        <Table 
          columns={columns} 
          dataSource={timecards} 
          rowKey={(record) => record.id || Math.random().toString(36).substring(2, 9)}
          pagination={false}
          size="small"
          scroll={{ x: 'max-content' }}
          rowClassName={(record) => {
            // Check if the record would have zero revenue
            if (!record.cost_code || !record.cost_code.name) return 'zero-revenue-row';
            if (!record.time_in || !record.time_out) return 'zero-revenue-row';
            
            const { regular, overtime, premium, premiumOvertime } = calculateHours(record.time_in, record.time_out);
            const costCodeName = record.cost_code?.name || '';
            const rate = findRateForCostCode(costCodeName);
            const revenue = (regular * rate) + 
                          (overtime * 1.5 * rate) + 
                          (premium * 2.25 * rate) + 
                          (premiumOvertime * 3.375 * rate);
            
            return revenue === 0 ? 'zero-revenue-row' : '';
          }}
          onRow={(record) => {
            // Add click handler for zero revenue rows
            const hasZeroRevenue = (!record.cost_code || !record.cost_code.name) || 
                                  (!record.time_in || !record.time_out) ||
                                  (findRateForCostCode(record.cost_code?.name || '') === 0);
            if (hasZeroRevenue) {
              return {
                onClick: () => {
                  // Link to the daily log for the specific date instead of the individual entry
                  const date = record.date || record.work_date;
                  const formattedDate = moment(date).format('YYYY-MM-DD');
                  window.open(
                    `https://us02.procore.com/webclients/host/companies/562949953425469/projects/${projectId}/tools/dailylog/list?date=${formattedDate}`,
                    '_blank'
                  );
                },
                style: { cursor: 'pointer' }
              };
            }
            return {};
          }}
        />
        <style jsx="true">{`
          .zero-revenue-row {
            background-color: #ffebe6;
          }
          .zero-revenue-row:hover > td {
            background-color: #ffd6cc !important;
          }
        `}</style>
      </div>
    );
  };
  
  // Render quantities details table
  const renderQuantitiesDetails = (quantities, warnings = [], projectId) => {
    // Filter quantity warnings
    const quantityWarnings = warnings.filter(w => w.type === 'quantity');
    
    const columns = [
      {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        render: (text) => formatDate(text),
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        render: (text) => text || 'N/A',
      },
      {
        title: 'Cost Code',
        dataIndex: 'cost_code',
        key: 'cost_code',
        render: (cost_code) => cost_code?.name || 'N/A',
      },
      {
        title: 'Quantity',
        dataIndex: 'quantity',
        key: 'quantity',
        render: (text) => text || '0',
      },
      {
        title: 'Unit',
        dataIndex: 'unit',
        key: 'unit',
        render: (text) => text || 'N/A',
      },
      {
        title: 'Revenue',
        key: 'revenue',
        render: (_, record) => {
          if (!record.cost_code || !record.cost_code.name) return '$0.00';
          
          const costCodeName = record.cost_code?.name || '';
          const rate = findRateForCostCode(costCodeName);
          const quantity = record.quantity || 0;
          const revenue = quantity * rate;
          return revenue === 0 ? 
            <span style={{ color: '#f5222d', fontWeight: 'bold' }}>$0.00</span> : 
            `$${revenue.toFixed(2)}`;
        },
      },
    ];

    return (
      <div>
        {quantityWarnings.length > 0 && (
          <div style={{ marginBottom: 16 }}>
            <Collapse ghost>
              <Collapse.Panel 
                header={
                  <span>
                    <WarningOutlined style={{ color: '#f5222d', marginRight: 8 }} />
                    {`${quantityWarnings.length} warning${quantityWarnings.length > 1 ? 's' : ''} found`}
                  </span>
                }
                key="warnings"
              >
                <ul style={{ paddingLeft: 20, marginBottom: 0 }}>
                  {quantityWarnings.map((warning, index) => (
                    <li key={index} style={{ marginBottom: 8 }}>
                      <Typography.Text type="danger">
                        {warning.message}: {' '}
                        <a 
                          href={`https://us02.procore.com/webclients/host/companies/562949953425469/projects/${projectId}/tools/dailylog/list?date=${moment(warning.entry.date).format('YYYY-MM-DD')}`}
                          target="_blank"
                          rel="noreferrer"
                          style={{ color: 'inherit' }}
                        >
                          <Typography.Text code style={{ color: '#f5222d' }}>
                            {JSON.stringify(warning.entry.id)}
                          </Typography.Text>
                        </a>
                      </Typography.Text>
                    </li>
                  ))}
                </ul>
              </Collapse.Panel>
            </Collapse>
          </div>
        )}
        <Table 
          columns={columns} 
          dataSource={quantities} 
          rowKey={(record) => record.id || Math.random().toString(36).substring(2, 9)}
          pagination={false}
          size="small"
          scroll={{ x: 'max-content' }}
          rowClassName={(record) => {
            if (!record.cost_code || !record.cost_code.name) return 'zero-revenue-row';
            
            const costCodeName = record.cost_code?.name || '';
            const rate = findRateForCostCode(costCodeName);
            const quantity = record.quantity || 0;
            const revenue = quantity * rate;
            
            return revenue === 0 ? 'zero-revenue-row' : '';
          }}
          onRow={(record) => {
            // Add click handler for zero revenue rows
            const hasZeroRevenue = (!record.cost_code || !record.cost_code.name) || 
                                 (findRateForCostCode(record.cost_code?.name || '') === 0);
            if (hasZeroRevenue) {
              return {
                onClick: () => {
                  // Link to the daily log for the specific date instead of the individual entry
                  const date = record.date;
                  const formattedDate = moment(date).format('YYYY-MM-DD');
                  window.open(
                    `https://us02.procore.com/webclients/host/companies/562949953425469/projects/${projectId}/tools/dailylog/list?date=${formattedDate}`,
                    '_blank'
                  );
                },
                style: { cursor: 'pointer' }
              };
            }
            return {};
          }}
        />
        <style jsx="true">{`
          .zero-revenue-row {
            background-color: #ffebe6;
          }
          .zero-revenue-row:hover > td {
            background-color: #ffd6cc !important;
          }
        `}</style>
      </div>
    );
  };
  
  // Render equipment details table
  const renderEquipmentDetails = (equipment, warnings = [], projectId) => {
    // Filter equipment warnings
    const equipmentWarnings = warnings.filter(w => w.type === 'equipment');
    
    const columns = [
      {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        render: (text) => formatDate(text),
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        render: (text) => text || 'N/A',
      },
      {
        title: 'Cost Code',
        dataIndex: 'cost_code',
        key: 'cost_code',
        render: (cost_code) => cost_code?.name || 'N/A',
      },
      {
        title: 'Notes',
        dataIndex: 'notes',
        key: 'notes',
        render: (text) => text || 'N/A',
      },
      {
        title: 'Quantity',
        key: 'quantity',
        render: (_, record) => {
          if (!record.notes) return '1';
          const match = record.notes.match(/^\d+/);
          return match ? match[0] : '1';
        },
      },
      {
        title: 'Revenue',
        key: 'revenue',
        render: (_, record) => {
          if (!record.cost_code || !record.cost_code.name) return '$0.00';
          
          const costCodeName = record.cost_code?.name || '';
          const rate = findRateForCostCode(costCodeName);
          let quantity = 1;
          if (record.notes) {
            const match = record.notes.match(/^\d+/);
            if (match) {
              quantity = parseInt(match[0], 10);
            }
          }
          const revenue = quantity * rate;
          return revenue === 0 ? 
            <span style={{ color: '#f5222d', fontWeight: 'bold' }}>$0.00</span> : 
            `$${revenue.toFixed(2)}`;
        },
      },
    ];

    return (
      <div>
        {equipmentWarnings.length > 0 && (
          <div style={{ marginBottom: 16 }}>
            <Collapse ghost>
              <Collapse.Panel 
                header={
                  <span>
                    <WarningOutlined style={{ color: '#f5222d', marginRight: 8 }} />
                    {`${equipmentWarnings.length} warning${equipmentWarnings.length > 1 ? 's' : ''} found`}
                  </span>
                }
                key="warnings"
              >
                <ul style={{ paddingLeft: 20, marginBottom: 0 }}>
                  {equipmentWarnings.map((warning, index) => (
                    <li key={index} style={{ marginBottom: 8 }}>
                      <Typography.Text type="danger">
                        {warning.message}: {' '}
                        <a 
                          href={`https://us02.procore.com/webclients/host/companies/562949953425469/projects/${projectId}/tools/dailylog/list?date=${moment(warning.entry.date).format('YYYY-MM-DD')}`}
                          target="_blank"
                          rel="noreferrer"
                          style={{ color: 'inherit' }}
                        >
                          <Typography.Text code style={{ color: '#f5222d' }}>
                            {JSON.stringify(warning.entry.id)}
                          </Typography.Text>
                        </a>
                      </Typography.Text>
                    </li>
                  ))}
                </ul>
              </Collapse.Panel>
            </Collapse>
          </div>
        )}
        <Table 
          columns={columns} 
          dataSource={equipment} 
          rowKey={(record) => record.id || Math.random().toString(36).substring(2, 9)}
          pagination={false}
          size="small"
          scroll={{ x: 'max-content' }}
          rowClassName={(record) => {
            if (!record.cost_code || !record.cost_code.name) return 'zero-revenue-row';
            
            const costCodeName = record.cost_code?.name || '';
            const rate = findRateForCostCode(costCodeName);
            let quantity = 1;
            if (record.notes) {
              const match = record.notes.match(/^\d+/);
              if (match) {
                quantity = parseInt(match[0], 10);
              }
            }
            const revenue = quantity * rate;
            
            return revenue === 0 ? 'zero-revenue-row' : '';
          }}
          onRow={(record) => {
            // Add click handler for zero revenue rows
            const hasZeroRevenue = (!record.cost_code || !record.cost_code.name) || 
                                 (findRateForCostCode(record.cost_code?.name || '') === 0);
            if (hasZeroRevenue) {
              return {
                onClick: () => {
                  // Link to the daily log for the specific date instead of the individual entry
                  const date = record.date;
                  const formattedDate = moment(date).format('YYYY-MM-DD');
                  window.open(
                    `https://us02.procore.com/webclients/host/companies/562949953425469/projects/${projectId}/tools/dailylog/list?date=${formattedDate}`,
                    '_blank'
                  );
                },
                style: { cursor: 'pointer' }
              };
            }
            return {};
          }}
        />
        <style jsx="true">{`
          .zero-revenue-row {
            background-color: #ffebe6;
          }
          .zero-revenue-row:hover > td {
            background-color: #ffd6cc !important;
          }
        `}</style>
      </div>
    );
  };

  // Render the chart for the summary card
  const renderSummaryChart = () => {
    if (!reportResults || reportResults.length === 0) return null;
    
    // Prepare data for the bar chart
    const barData = {
      labels: reportResults.map(project => project.project.name.substring(0, 15) + '...'),
      datasets: [
        {
          label: 'Labor',
          data: reportResults.map(project => project.editedCosts.labor),
          backgroundColor: 'rgba(54, 162, 235, 0.6)',
        },
        {
          label: 'Quantities',
          data: reportResults.map(project => project.editedCosts.quantities),
          backgroundColor: 'rgba(255, 206, 86, 0.6)',
        },
        {
          label: 'Equipment',
          data: reportResults.map(project => project.editedCosts.equipment),
          backgroundColor: 'rgba(75, 192, 192, 0.6)',
        }
      ]
    };
    
    const barOptions = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          stacked: true,
        },
        y: {
          stacked: true,
          beginAtZero: true,
        }
      },
      plugins: {
        legend: {
          position: 'top',
        },
        title: {
          display: true,
          text: 'Project Revenue Breakdown'
        }
      }
    };
    
    return (
      <div className="chart-container">
        <div className="bar-chart" style={{ height: '300px', marginBottom: '20px' }}>
          <Bar data={barData} options={barOptions} />
        </div>
      </div>
    );
  };

  // Render Daily Report Results - Updated version
  const renderReportResults = () => {
    if (!reportResults) return null;

    if (reportResults.length === 0) {
      return (
        <Card className="report-results">
          <Empty description="No active projects found with timecard entries on this date" />
        </Card>
      );
    }

    // Calculate total costs from edited values
    const totalDailyCost = reportResults.reduce(
      (sum, project) => sum + project.editedCosts.total, 0
    );

    return (
      <div className="report-results">
        <Title level={5} style={{ marginBottom: '16px' }}>Daily Report for {reportDate.format('MM/DD/YYYY')}</Title>
        
        {/* Summary Card with Chart */}
        <Card 
          style={{ 
            backgroundColor: '#f6ffed', 
            borderLeft: '4px solid #52c41a',
            marginBottom: '24px'
          }}
          className="summary-card"
        >
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}>
            <div>
              <Text strong style={{ fontSize: '16px' }}>Summary</Text>
              <div style={{ marginTop: '12px' }}>
                <Text>Total Projects: {reportResults.length}</Text>
                <div style={{ marginTop: '8px', fontSize: '18px', fontWeight: 'bold' }}>
                  Total Daily Revenue: ${totalDailyCost.toFixed(2)}
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', gap: '16px' }}>
              <Statistic 
                title="Labor" 
                value={reportResults.reduce((sum, project) => sum + project.editedCosts.labor, 0).toFixed(2)} 
                prefix="$" 
                valueStyle={{ color: '#3f8600' }}
              />
              <Statistic 
                title="Quantities" 
                value={reportResults.reduce((sum, project) => sum + project.editedCosts.quantities, 0).toFixed(2)} 
                prefix="$" 
                valueStyle={{ color: '#faad14' }}
              />
              <Statistic 
                title="Equipment" 
                value={reportResults.reduce((sum, project) => sum + project.editedCosts.equipment, 0).toFixed(2)} 
                prefix="$" 
                valueStyle={{ color: '#1890ff' }}
              />
            </div>
          </div>
          
          {renderSummaryChart()}
        </Card>
        
        {/* Project Cards */}
        {reportResults.map(projectReport => {
          const projectId = projectReport.project.id;
          const cardStatus = getProjectCardStatus(projectId);
          const isExpanded = expandedProjects[projectId] || false;
          
          // Determine card styling based on status
          let cardStyle = { marginBottom: '16px' };
          let borderStyle = {};
          
          if (cardStatus === 'edited') {
            cardStyle.backgroundColor = '#fff1f0';
            borderStyle.borderLeft = '3px solid #f5222d';
          } else if (cardStatus === 'resolved') {
            cardStyle.backgroundColor = '#f6ffed';
            borderStyle.borderLeft = '3px solid #52c41a';
          }
          
          return (
            <Card 
              key={projectId}
              className={`project-card ${projectReport.timecards.length === 0 ? 'no-data' : ''}`}
              style={{...cardStyle, ...borderStyle}}
            >
              <div className="project-title">
                {/* PROD/DEV/LOCAL SWITCH */}
                <a 
                  href={`https://us02.procore.com/${projectId}/project/home`} // prod
                  // href={`https://sandbox.procore.com/${projectId}/project/home`} // sandbox
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: 'inherit', textDecoration: 'none' }}
                >
                  <Text strong style={{ fontSize: '16px' }}>{projectReport.project.name}</Text>
                </a>
                <Text type="secondary">ID: {projectId}</Text>
              </div>
              
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                {/* Show Details button with hyperlink to Procore daily log */}
                <Button 
                  type="link" 
                  onClick={() => toggleProjectDetails(projectId)}
                  icon={isExpanded ? <UpOutlined /> : <DownOutlined />}
                  style={{ padding: '0', marginRight: '8px' }}
                >
                  {isExpanded ? 'Hide Details' : 'Show Details'}
                </Button>
                
                {/* PROD/DEV/LOCAL SWITCH */}
                <a
                  href={`https://us02.procore.com/webclients/host/companies/562949953425469/projects/${projectId}/tools/dailylog/list?date=${reportDate.format('YYYY-MM-DD')}`} // prod
                  // href={`https://sandbox.procore.com/webclients/host/companies/4271567/projects/${projectId}/tools/dailylog/list?date=${reportDate.format('YYYY-MM-DD')}`} // sandbox
                  target="_blank"
                  rel="noreferrer"
                  style={{ marginRight: '16px' }}
                >
                  <Button type="link" icon={<FileTextOutlined />} style={{ padding: '0' }}>
                    View in Procore
                  </Button>
                </a>
                
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Tag color="blue" icon={<UserOutlined />}>
                    Timecards: {projectReport.timecards.length}
                  </Tag>
                  <Tag color="orange" icon={<ShoppingOutlined />} style={{ marginLeft: '8px' }}>
                    Quantities: {projectReport.quantities.length}
                  </Tag>
                  <Tag color="green" icon={<ToolOutlined />} style={{ marginLeft: '8px' }}>
                    Equipment: {projectReport.equipment.length}
                  </Tag>
                  {projectReport.hasWarnings && (
                    <Tag color="error" icon={<WarningOutlined />} style={{ marginLeft: '8px' }}>
                      Warnings: {projectReport.warnings.length}
                    </Tag>
                  )}
                </div>
              </div>
              
              {/* Detailed data tables */}
              {isExpanded && (
                <div className="project-details">
                  <Collapse defaultActiveKey={['1']} ghost>
                    {projectReport.timecards.length > 0 && (
                      <Collapse.Panel 
                        header={<span><UserOutlined /> Labor Details ({projectReport.timecards.length} entries)</span>} 
                        key="1"
                      >
                        {renderTimecardDetails(projectReport.timecards, projectReport.warnings, projectId)}
                      </Collapse.Panel>
                    )}
                    
                    {projectReport.quantities.length > 0 && (
                      <Collapse.Panel 
                        header={<span><ShoppingOutlined /> Quantities Details ({projectReport.quantities.length} entries)</span>} 
                        key="2"
                      >
                        {renderQuantitiesDetails(projectReport.quantities, projectReport.warnings, projectId)}
                      </Collapse.Panel>
                    )}
                    
                    {projectReport.equipment.length > 0 && (
                      <Collapse.Panel 
                        header={<span><ToolOutlined /> Equipment Details ({projectReport.equipment.length} entries)</span>} 
                        key="3"
                      >
                        {renderEquipmentDetails(projectReport.equipment, projectReport.warnings, projectId)}
                      </Collapse.Panel>
                    )}
                  </Collapse>
                </div>
              )}
              
              {/* Edit message for edited fields */}
              {cardStatus === 'edited' && (
                <div style={{ 
                  backgroundColor: '#fff1f0', 
                  padding: '8px', 
                  borderRadius: '4px',
                  marginTop: '8px',
                  marginBottom: '8px'
                }}>
                  <WarningOutlined style={{ color: '#f5222d', marginRight: '8px' }} />
                  <Text type="danger">{getEditMessage(projectId)}</Text>
                </div>
              )}
              
              <div className="cost-breakdown">
                {/* Labor Cost - Editable */}
                <div className={`cost-item ${isFieldEdited(projectId, 'labor') && !isEditResolved(projectId, 'labor') ? 'edited' : ''}`}>
                  <div className="cost-item-title">
                    Labor Revenue
                    {isFieldEdited(projectId, 'labor') && !isEditResolved(projectId, 'labor') && (
                      <Button 
                        type="primary" 
                        size="small" 
                        icon={<CheckCircleOutlined />}
                        onClick={() => handleResolveEdit(projectId, 'labor')}
                        style={{ marginLeft: '8px' }}
                      >
                        Resolve
                      </Button>
                    )}
                  </div>
                  <div className="cost-item-value">
                    $
                    <Input
                      style={{ width: '100px', marginLeft: '4px' }}
                      value={getInputValue(projectId, 'labor', projectReport.editedCosts.labor)}
                      onChange={(e) => handleCostEdit(projectId, 'labor', e.target.value)}
                      suffix={<EditOutlined />}
                      onFocus={(e) => e.target.select()}
                    />
                  </div>
                </div>
                
                {/* Quantities Cost - Editable */}
                <div className={`cost-item ${isFieldEdited(projectId, 'quantities') && !isEditResolved(projectId, 'quantities') ? 'edited' : ''}`}>
                  <div className="cost-item-title">
                    Quantities Revenue
                    {isFieldEdited(projectId, 'quantities') && !isEditResolved(projectId, 'quantities') && (
                      <Button 
                        type="primary" 
                        size="small" 
                        icon={<CheckCircleOutlined />}
                        onClick={() => handleResolveEdit(projectId, 'quantities')}
                        style={{ marginLeft: '8px' }}
                      >
                        Resolve
                      </Button>
                    )}
                  </div>
                  <div className="cost-item-value">
                    $
                    <Input
                      style={{ width: '100px', marginLeft: '4px' }}
                      value={getInputValue(projectId, 'quantities', projectReport.editedCosts.quantities)}
                      onChange={(e) => handleCostEdit(projectId, 'quantities', e.target.value)}
                      suffix={<EditOutlined />}
                      onFocus={(e) => e.target.select()}
                    />
                  </div>
                </div>
                
                {/* Equipment Cost - Editable */}
                <div className={`cost-item ${isFieldEdited(projectId, 'equipment') && !isEditResolved(projectId, 'equipment') ? 'edited' : ''}`}>
                  <div className="cost-item-title">
                    Equipment Revenue
                    {isFieldEdited(projectId, 'equipment') && !isEditResolved(projectId, 'equipment') && (
                      <Button 
                        type="primary" 
                        size="small" 
                        icon={<CheckCircleOutlined />}
                        onClick={() => handleResolveEdit(projectId, 'equipment')}
                        style={{ marginLeft: '8px' }}
                      >
                        Resolve
                      </Button>
                    )}
                  </div>
                  <div className="cost-item-value">
                    $
                    <Input
                      style={{ width: '100px', marginLeft: '4px' }}
                      value={getInputValue(projectId, 'equipment', projectReport.editedCosts.equipment)}
                      onChange={(e) => handleCostEdit(projectId, 'equipment', e.target.value)}
                      suffix={<EditOutlined />}
                      onFocus={(e) => e.target.select()}
                    />
                  </div>
                </div>
                
                {/* Total Project Cost - Calculated */}
                <div className="cost-item" style={{ backgroundColor: '#e6f7ff', borderLeft: '3px solid #1890ff' }}>
                  <div className="cost-item-title">Total Project Revenue</div>
                  <div className="cost-item-value">${projectReport.editedCosts.total.toFixed(2)}</div>
                </div>
              </div>
            </Card>
          );
        })}
      </div>
    );
  };

  // Helper functions for cost calculation with cache for consistency
  const calculateLaborCostWithCache = (timecards, rateCache) => {
    if (!Array.isArray(timecards)) {
      console.error("Invalid timecards data:", timecards);
      return 0;
    }
    
    let totalCost = 0;

    timecards.forEach(entry => {
      try {
        // Skip entries with missing cost codes
        if (!entry.cost_code || !entry.cost_code.name) {
          console.warn("Timecard entry missing cost code:", entry);
          return;
        }
        
        // Calculate hours with fallback logic
        let regular, overtime, premium, premiumOvertime;
        
        if (!entry.time_in || !entry.time_out) {
          console.warn("Timecard entry missing time_in or time_out:", entry);
          // Use metadata hours if time_in or time_out is missing
          regular = entry.metadata?.raw_data?.hours ? Number(entry.metadata.raw_data.hours) : 0;
          overtime = 0;
          premium = 0;
          premiumOvertime = 0;
        } else {
          const hours = calculateHours(entry.time_in, entry.time_out);
          const totalHours = hours.regular + hours.overtime + hours.premium + hours.premiumOvertime;
          
          if (totalHours > 22) {
            // If hours exceed 22, fallback to metadata hours
            console.warn(`Timecard entry has excessive hours (${totalHours}), using metadata hours:`, entry);
            regular = entry.metadata?.raw_data?.hours ? Number(entry.metadata.raw_data.hours) : 0;
            overtime = 0;
            premium = 0;
            premiumOvertime = 0;
          } else {
            regular = hours.regular;
            overtime = hours.overtime;
            premium = hours.premium;
            premiumOvertime = hours.premiumOvertime;
          }
        }
        
        // Get rate from cost code with cache
        const costCodeName = entry.cost_code?.name || '';
        // Use cached rate if available, otherwise calculate and cache it
        let rate;
        if (rateCache[costCodeName] !== undefined) {
          rate = rateCache[costCodeName];
        } else {
          rate = findRateForCostCode(costCodeName);
          rateCache[costCodeName] = rate;
        }
        
        // If rate is 0 (missing or unmapped cost code), skip revenue calculation
        if (rate === 0) {
          return;
        }
        
        // Calculate cost based on hours and rates
        const cost = (regular * rate) + 
                    (overtime * 1.5 * rate) + 
                    (premium * 2.25 * rate) + 
                    (premiumOvertime * 3.375 * rate);
        
        totalCost += cost;
      } catch (error) {
        console.error("Error calculating labor cost for entry:", entry, error);
      }
    });

    return totalCost;
  };

  const calculateQuantitiesCostWithCache = (quantities, rateCache) => {
    if (!Array.isArray(quantities)) {
      console.error("Invalid quantities data:", quantities);
      return 0;
    }
    
    let totalCost = 0;

    quantities.forEach(entry => {
      try {
        // Skip entries with missing cost codes
        if (!entry.cost_code || !entry.cost_code.name) {
          console.warn("Quantities entry missing cost code:", entry);
          return;
        }
        
        const costCodeName = entry.cost_code?.name || '';
        // Use cached rate if available, otherwise calculate and cache it
        let rate;
        if (rateCache[costCodeName] !== undefined) {
          rate = rateCache[costCodeName];
        } else {
          rate = findRateForCostCode(costCodeName);
          rateCache[costCodeName] = rate;
        }
        
        // If rate is 0 (missing or unmapped cost code), skip revenue calculation
        if (rate === 0) {
          return;
        }
        
        const quantity = entry.quantity || 0;
        
        totalCost += quantity * rate;
      } catch (error) {
        console.error("Error calculating quantities cost for entry:", entry, error);
      }
    });

    return totalCost;
  };

  const calculateEquipmentCostWithCache = (equipment, rateCache) => {
    if (!Array.isArray(equipment)) {
      console.error("Invalid equipment data:", equipment);
      return 0;
    }
    
    let totalCost = 0;

    equipment.forEach(entry => {
      try {
        // Skip entries with missing cost codes
        if (!entry.cost_code || !entry.cost_code.name) {
          console.warn("Equipment entry missing cost code:", entry);
          return;
        }
        
        const costCodeName = entry.cost_code?.name || '';
        // Use cached rate if available, otherwise calculate and cache it
        let rate;
        if (rateCache[costCodeName] !== undefined) {
          rate = rateCache[costCodeName];
        } else {
          rate = findRateForCostCode(costCodeName);
          rateCache[costCodeName] = rate;
        }
        
        // If rate is 0 (missing or unmapped cost code), skip revenue calculation
        if (rate === 0) {
          return;
        }
        
        // Extract quantity from notes if available
        let quantity = 1;
        if (entry.notes) {
          const match = entry.notes.match(/^\d+/);
          if (match) {
            quantity = parseInt(match[0], 10);
          }
        }
        
        totalCost += quantity * rate;
      } catch (error) {
        console.error("Error calculating equipment cost for entry:", entry, error);
      }
    });

    return totalCost;
  };

  // Original calculation functions for backward compatibility
  const calculateLaborCost = (timecards) => {
    return calculateLaborCostWithCache(timecards, {});
  };

  const calculateQuantitiesCost = (quantities) => {
    return calculateQuantitiesCostWithCache(quantities, {});
  };

  const calculateEquipmentCost = (equipment) => {
    return calculateEquipmentCostWithCache(equipment, {});
  };

  // Improved findRateForCostCode function with better consistency
  const findRateForCostCode = (costCodeName) => {
    if (!costCodeName) return 0; // Return 0 instead of 50 when cost code is missing
    
    // Normalize the cost code name to handle case and whitespace variations
    const normalizedCostCode = normalizeString(costCodeName);
    
    // Create normalized map
    const normalizedCostCodeMap = {};
    for (let key in cost_code_map) {
      const normalizedKey = normalizeString(key);
      normalizedCostCodeMap[normalizedKey] = cost_code_map[key];
    }
    
    // Look up rate in the normalized map
    const rate = normalizedCostCodeMap[normalizedCostCode];
    
    // If found, return the rate; otherwise use a default of 0
    return rate || 0;
  };

  const renderHourStat = (title, value, color, subtitle) => {
    return (
      <div className="hour-stat">
        <div className="hour-stat-header">
          <Text strong>{title}</Text>
          {value > 0 ? (
            <StyledBadge color={color} count={value} overflowCount={999} />
          ) : (
            <Text>0</Text>
          )}
        </div>
        <Text type="secondary" style={{ fontSize: '12px' }}>{subtitle}</Text>
      </div>
    );
  };

  const renderCalculatorResults = () => {
    if (!calcResults) return null;

    // Calculate the time difference in human-readable format
    const startDateTime = moment(calcStartDate)
      .hour(calcStartTime.hour())
      .minute(calcStartTime.minute());
      
    const endDateTime = moment(calcEndDate)
      .hour(calcEndTime.hour())
      .minute(calcEndTime.minute());
      
    const duration = moment.duration(endDateTime.diff(startDateTime));
    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();
    const durationText = `${hours}h ${minutes}m`;

    return (
      <Card className="results-card">
        <Title level={5} className="results-title">Hours Calculation Results</Title>
        
        <div className="results-grid">
          {renderHourStat("Regular Hours", calcResults.regular, "#52c41a", "Standard rate (1.0x)")}
          {renderHourStat("Overtime", calcResults.overtime, "#faad14", "Overtime rate (1.5x)")}
          {renderHourStat("Premium", calcResults.premium, "#1890ff", "Premium rate (2.25x)")}
          {renderHourStat("Premium OT", calcResults.premiumOvertime, "#f5222d", "Premium OT rate (3.375x)")}
        </div>
        
        <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px', backgroundColor: '#f5f7ff', padding: '12px', borderRadius: '6px' }}>
            <ClockCircleOutlined style={{ color: '#123286' }} />
            <div>
              <Text strong>Time Period: </Text>
              <Text>{calcStartDate?.format('MM/DD/YYYY')} {calcStartTime?.format('h:mm A')} to {calcEndDate?.format('MM/DD/YYYY')} {calcEndTime?.format('h:mm A')}</Text>
              <Text type="secondary" style={{ marginLeft: '8px' }}>({durationText})</Text>
            </div>
          </div>
          
          <div className="effective-pay-container">
            <div>
              <Text style={{ color: 'white', fontSize: '18px', fontWeight: '600' }}>Total Hours: {calcResults.regular + calcResults.overtime + calcResults.premium + calcResults.premiumOvertime}</Text>
              <div style={{ color: 'rgba(255, 255, 255, 0.85)', fontSize: '14px', marginTop: '4px' }}>Effective Pay Hours: {calcResults.effectivePayHours}</div>
            </div>
            <div className="effective-pay-value">{calcResults.regular + calcResults.overtime + calcResults.premium + calcResults.premiumOvertime}</div>
          </div>
        </div>
      </Card>
    );
  };

  return (
    <EquipmentWrapper>
      {/* Invoice Generator Section */}
      <div className="section-card">
        <div className="section-header">
          <FileExcelOutlined />
          <Title level={4} style={{ margin: 0 }}>Invoice Generator</Title>
        </div>
        
        <div className="form-row">
          <div className="form-item">
            <label>Select Project</label>
            <Select
              className="selectProject"
              showSearch
              placeholder="Select a project"
              optionFilterProp="children"
              onChange={handleProjectChange}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              loading={projects.length === 0}
            >
              {projects.map((project) => (
                <Option key={project.id} value={project.id}>
                  {project.name}
                </Option>
              ))}
            </Select>
          </div>
          
          <div className="form-item">
            <label>Start Date</label>
            <DatePicker
              onChange={handleStartDateChange}
              disabledDate={disabledStartDate}
              format="MM/DD/YYYY"
              value={startDate}
              allowClear={false}
              inputReadOnly={true}
              mode="date"
              showToday={false}
              style={{ width: '100%' }}
            />
          </div>
          
          <div className="form-item">
            <label>End Date</label>
            <DatePicker
              onChange={handleEndDateChange}
              disabledDate={disabledEndDate}
              format="MM/DD/YYYY"
              value={endDate}
              allowClear={false}
              inputReadOnly={true}
              mode="date"
              showToday={false}
              style={{ width: '100%' }}
            />
          </div>
        </div>
        
        <div className="form-row">
          <div className="form-item">
            <label>Output Format</label>
            <Radio.Group onChange={handleFormatChange} value={selectedFormat} style={{ width: '100%' }}>
              <Radio.Button value="excel" style={{ width: '50%', textAlign: 'center' }}>
                <FileExcelOutlined /> Excel
              </Radio.Button>
              <Radio.Button value="pdf" style={{ width: '50%', textAlign: 'center' }}>
                <FilePdfOutlined /> PDF
              </Radio.Button>
            </Radio.Group>
          </div>
          
          <div className="form-item" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
            <Button
              type="primary"
              className={`generate-button ${selectedFormat === 'excel' ? 'excel-button' : 'pdf-button'}`}
              icon={selectedFormat === 'excel' ? <FileExcelOutlined /> : <FilePdfOutlined />}
              onClick={handleGenerate}
              loading={isGenerating}
              disabled={!selectedProject || !startDate || !endDate}
            >
              Generate {selectedFormat === 'excel' ? 'Excel' : 'PDF'}
            </Button>
          </div>
        </div>
      </div>

      {/* Daily Billing Generator Section */}
      <div className="section-card">
        <div className="section-header">
          <FileTextOutlined />
          <Title level={4} style={{ margin: 0 }}>Daily Billing Generator</Title>
        </div>
        
        <div className="calculator-form">
          <div className="form-item">
            <label>Report Date</label>
            <DatePicker
              onChange={handleReportDateChange}
              disabledDate={disabledReportDate}
              format="MM/DD/YYYY"
              value={reportDate}
              allowClear={false}
              inputReadOnly={true}
              mode="date"
              style={{ width: '100%' }}
            />
          </div>
          
          <Button
            type="primary"
            style={{ height: '40px', minWidth: '180px' }}
            icon={<FileTextOutlined />}
            onClick={handleGenerateReport}
            loading={isGeneratingReport}
            disabled={!reportDate}
          >
            Create Daily Report
          </Button>
        </div>
        
        {isGeneratingReport ? (
          <div style={{ textAlign: 'center', padding: '40px 0' }}>
            <Spin size="large" />
            <div style={{ marginTop: '16px' }}>Generating report for all active projects...</div>
          </div>
        ) : (
          renderReportResults()
        )}
      </div>

      {/* Hours Calculator Section */}
      <div className="section-card">
        <div className="section-header">
          <CalculatorOutlined />
          <Title level={4} style={{ margin: 0 }}>Hours Calculator</Title>
        </div>
        
        <div className="calculator-form">
          <div className="time-column">
            <div className="time-input-group">
              <label>Start Date & Time</label>
              <div className="time-input-row">
                <DatePicker
                  className="time-picker"
                  onChange={handleCalcStartDateChange}
                  format="MM/DD/YYYY"
                  value={calcStartDate}
                  placeholder="Select date"
                />
                <TimePicker
                  className="time-picker"
                  onChange={handleCalcStartTimeChange}
                  format="h:mm A"
                  value={calcStartTime}
                  use12Hours
                  placeholder="Select time"
                />
              </div>
            </div>
            
            <div className="time-input-group">
              <label>End Date & Time</label>
              <div className="time-input-row">
                <DatePicker
                  className="time-picker"
                  onChange={handleCalcEndDateChange}
                  format="MM/DD/YYYY"
                  value={calcEndDate}
                  placeholder="Select date"
                />
                <TimePicker
                  className="time-picker"
                  onChange={handleCalcEndTimeChange}
                  format="h:mm A"
                  value={calcEndTime}
                  use12Hours
                  placeholder="Select time"
                />
              </div>
            </div>
          </div>
          
          <Button
            type="primary"
            style={{ height: '40px', minWidth: '180px' }}
            icon={<CalculatorOutlined />}
            onClick={handleCalculateHours}
            loading={isCalculating}
            disabled={!calcStartDate || !calcStartTime || !calcEndDate || !calcEndTime}
          >
            Calculate Hours
          </Button>
        </div>
        
        {renderCalculatorResults()}
      </div>
    </EquipmentWrapper>
  );
};