import ExcelJS from 'exceljs';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { getTimecardList, getQuantitiesList, getEquipmentList } from '../services/Api/collection';
import * as formattingConstants from './formattingConstant';
import { calculateHours, formatTime, cost_code_map, extractNumberFromString, normalizeString, getBase64FromImageUrl } from './invoiceUtils';
import { restoreFastLogo as logoUrl } from '../assets/index';

// Initialize pdfMake with fonts
if (pdfMake && !pdfMake.vfs) {
    pdfMake.vfs = pdfFonts && pdfFonts.pdfMake ? pdfFonts.pdfMake.vfs : {};
  }
// Import formatting constants
const {
    LABOR_HEADING_COLOR,
    LABOR_HEADING_PATTERN,
    LABOR_HEADING_TYPE,
    LABOR_HEADING_ALIGNMENT,
    LABOR_COLUMN_WIDTHS,
    LABOR_COLUMN_COLOR,
    LABOR_COLUMN_PATTERN,
    LABOR_COLUMN_TYPE,
    LABOR_COLUMN_ALIGNMENT,
    ALL_OUTLINE,
    ALL_OUTLINE_COLOR,
} = formattingConstants;

/**
 * Format cell in Excel
 */
function formatCell(cell, type, pattern, color, alignment, style, outline_color) {
    cell.border = {
        top: { style: style, color: { argb: outline_color } },
        left: { style: style, color: { argb: outline_color } },
        bottom: { style: style, color: { argb: outline_color } },
        right: { style: style, color: { argb: outline_color } }
    };
    cell.fill = {
        type: type,
        pattern: pattern,
        fgColor: { argb: color }
    };
    cell.alignment = { vertical: alignment.vertical, horizontal: alignment.horizontal };
}

/**
 * Generate white space in Excel
 */
function generateWhiteSpace(worksheet) {
    worksheet.addRow([""]);
}

/**
 * Generate labor heading for Excel
 */
function generateLaborHeading(worksheet) {
    const newRow = worksheet.addRow(["Labor"]);
    worksheet.mergeCells(`A${newRow.number}:K${newRow.number}`);
    const mergedCell = worksheet.getCell(`A${newRow.number}`);
    formatCell(mergedCell, LABOR_HEADING_TYPE, LABOR_HEADING_PATTERN, LABOR_HEADING_COLOR, LABOR_HEADING_ALIGNMENT, ALL_OUTLINE, ALL_OUTLINE_COLOR);
}

/**
 * Generate quantities header for Excel
 */
function generateQuantitiesHeader(worksheet) {
    const newRow = worksheet.addRow(["Quantities"]);
    worksheet.mergeCells(`A${newRow.number}:E${newRow.number}`);
    const mergedCell = worksheet.getCell(`A${newRow.number}`);
    formatCell(mergedCell, LABOR_HEADING_TYPE, LABOR_HEADING_PATTERN, LABOR_HEADING_COLOR, LABOR_HEADING_ALIGNMENT, ALL_OUTLINE, ALL_OUTLINE_COLOR);
}

/**
 * Generate equipment header for Excel
 */
function generateEquipmentHeader(worksheet) {
    const newRow = worksheet.addRow(["Equipment"]);
    worksheet.mergeCells(`A${newRow.number}:E${newRow.number}`);
    const mergedCell = worksheet.getCell(`A${newRow.number}`);
    formatCell(mergedCell, LABOR_HEADING_TYPE, LABOR_HEADING_PATTERN, LABOR_HEADING_COLOR, LABOR_HEADING_ALIGNMENT, ALL_OUTLINE, ALL_OUTLINE_COLOR);
}

/**
 * Generate labor columns for Excel
 */
function generateLaborColumns(worksheet) {
    const row = worksheet.addRow(["Date", "Name", "Title", "Time-In", "Time-Out", "Reg Hours", "Reg Rate", "OT Hrs", "2X OT Hrs", "Premium 2X OT Hrs", "Total Charge"]);
    LABOR_COLUMN_WIDTHS.forEach((width, index) => {
        worksheet.getColumn(index + 1).width = width;
    });

    for (let i = 1; i <= row.cellCount; i++) {
        const cell = worksheet.getCell(2, i);
        formatCell(cell, LABOR_COLUMN_TYPE, LABOR_COLUMN_PATTERN, LABOR_COLUMN_COLOR, LABOR_COLUMN_ALIGNMENT, ALL_OUTLINE, ALL_OUTLINE_COLOR);
    }
}

/**
 * Generate quantities columns for Excel
 */
function generateQuantitiesColumns(worksheet) {
    const row = worksheet.addRow(["Date", "Cost Code", "Quantity", "Rate", "Total"]);
    // Format the rows here as needed
}

/**
 * Generate equipment columns for Excel
 */
function generateEquipmentColumns(worksheet) {
    const row = worksheet.addRow(["Date", "Cost Code", "Quantity", "Rate", "Total"]);
    // Format the rows here as needed
}

/**
 * Format date for display in the PDF
 */
function formatDateForDisplay(date) {
    return new Date(date).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
    });
}

/**
 * Generate labor data for Excel
 */
function generateLaborData(worksheet, timecards, start_date, end_date) {
    generateLaborHeading(worksheet);
    generateLaborColumns(worksheet);
    let currentDate = new Date(start_date);
    const totals = [];
  
    while (currentDate <= end_date) {
      const currentDateString = currentDate.toISOString().split('T')[0];
      const currDateTimecards = timecards.filter(entry => entry.date === currentDateString);
  
      if (currDateTimecards.length === 0) {
        currentDate.setDate(currentDate.getDate() + 1);
        continue;
      }
  
      const startingRow = worksheet.lastRow.number + 1;
      currDateTimecards.forEach((entry) => {
        // Get hours with fallback logic for high hours or missing time entries
        let regularHours, overtimeHours, premiumHours, premiumOvertime, totalHours;
        
        if (!entry.time_in || !entry.time_out) {
          // If time_in or time_out missing, try to use metadata hours
          regularHours = entry.metadata?.raw_data?.hours ? Number(entry.metadata.raw_data.hours) : 0;
          overtimeHours = 0;
          premiumHours = 0;
          premiumOvertime = 0;
          totalHours = regularHours;
        } else {
          // Calculate hours normally
          const hours = calculateHours(entry.time_in, entry.time_out);
          regularHours = hours.regular;
          overtimeHours = hours.overtime;
          premiumHours = hours.premium;
          premiumOvertime = hours.premiumOvertime;
          totalHours = regularHours + overtimeHours + premiumHours + premiumOvertime;
          
          // If total hours exceeds 22, fallback to metadata hours
          if (totalHours > 22) {
            regularHours = entry.metadata?.raw_data?.hours ? Number(entry.metadata.raw_data.hours) : 0;
            overtimeHours = 0;
            premiumHours = 0;
            premiumOvertime = 0;
            totalHours = regularHours;
          }
        }

        const current_row = worksheet.lastRow.number + 1;
        const cost_code_name = entry.cost_code?.name || 'N/A';
        const normalizedCostCodeMap = {};
        for (let key in cost_code_map) {
          const normalizedKey = normalizeString(key);
          normalizedCostCodeMap[normalizedKey] = cost_code_map[key];
        }
        
        // Use $0 rate if cost code is missing or not found in the map (instead of 50)
        const normalizedCostCode = normalizeString(cost_code_name);
        const rate = (normalizedCostCode && cost_code_name !== 'N/A') ? normalizedCostCodeMap[normalizedCostCode] || 0 : 0;
        
        // Calculate revenue to determine if row needs highlighting
        const totalRevenue = (regularHours * rate) + 
                           (overtimeHours * 1.5 * rate) + 
                           (premiumHours * 2.25 * rate) + 
                           (premiumOvertime * 3.375 * rate);
        
        // Check if this row has a warning condition (missing cost code or zero revenue)
        const hasWarning = cost_code_name === 'N/A' || !entry.cost_code || !entry.cost_code.name || totalRevenue === 0;
        
        // Try different possible field names for employee data instead of just entry.party?.name
        const employeeName = 
          (entry.metadata?.raw_data?.party?.name) ||
          (entry.user?.name) || 
          (entry.employee?.name) || 
          (entry.labor_entry_user?.name) ||
          (entry.created_by?.name) ||
          (entry.party?.name) ||
          'Unknown';
  
        // Added row with updated columns:
        // Columns: Date, Name, Title, Time-In, Time-Out, Reg Hours, Reg Rate, OT Hrs, Premium Hrs, Travel Hrs, Total Hrs, Total Charge
        const row = worksheet.addRow([
          current_row === startingRow ? currentDate.toISOString().split('T')[0] : "",
          employeeName,
          cost_code_name,
          formatTime(entry.time_in) || 'N/A',
          formatTime(entry.time_out) || 'N/A',
          regularHours,
          rate,
          overtimeHours,
          premiumHours,
          premiumOvertime, // Travel Hours (unchanged)
          { formula: `=(F${current_row}*G${current_row}) + (1.5 * H${current_row} * G${current_row}) + (1.5 * 1.5 * G${current_row} * I${current_row}) + (1.5 * 1.5 * 1.5 * G${current_row} * J${current_row})` } // Total Charge = Total Hrs * Reg Rate
        ]);
        
        // Apply red fill to rows with warnings
        if (hasWarning) {
          // Apply light red background to the entire row
          row.eachCell({ includeEmpty: true }, function(cell) {
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'FFFFD6D6' } // Light red
            };
          });
        }
      });
      const endingRow = worksheet.lastRow.number;
      worksheet.addRow([
        "", "", "", "", "", "", "", "", "", "Day Total:",
        { formula: `=SUM(K${startingRow}:K${endingRow})` }
      ]);
      totals.push(worksheet.lastRow.number);
  
      generateWhiteSpace(worksheet);
      currentDate.setDate(currentDate.getDate() + 1);
    }
  
    let formula = "=";
    totals.forEach((row) => { formula += `K${row} + `; });
    worksheet.addRow([
      "", "", "", "", "", "", "", "", "", "Labor Total:",
      { formula: formula.slice(0, -2) }
    ]);
  
    generateWhiteSpace(worksheet);
}

/**
 * Generate quantities data for Excel
 */
function generateQuantitiesData(worksheet, quantitiesData, start_date, end_date) {
    generateQuantitiesHeader(worksheet);
    generateQuantitiesColumns(worksheet);
    var currentDate = new Date(start_date);
    var totals = [];

    while (currentDate <= end_date) {
        const currentDateString = currentDate.toISOString().split('T')[0];
        const currDateQuantities = quantitiesData.filter(entry => entry.date === currentDateString);

        if (currDateQuantities.length === 0) {
            currentDate.setDate(currentDate.getDate() + 1);
            continue;
        }

        let startingRow = worksheet.lastRow.number + 1;
        currDateQuantities.forEach((entry) => {
            let current_row = worksheet.lastRow.number + 1;
            let cost_code_name = entry.cost_code?.name || 'N/A';
            const normalizedCostCodeMap = {};
            for (let key in cost_code_map) {
                const normalizedKey = normalizeString(key);
                normalizedCostCodeMap[normalizedKey] = cost_code_map[key];
            }
            // Use $0 rate if cost code is missing or not found in the map (instead of 65)
            const normalizedCostCode = normalizeString(cost_code_name);
            const rate = (normalizedCostCode && cost_code_name !== 'N/A') ? normalizedCostCodeMap[normalizedCostCode] || 0 : 0;
            
            const quantity = entry.quantity || 0;
            const totalRevenue = quantity * rate;
            
            // Check if this row has a warning condition (missing cost code or zero revenue)
            const hasWarning = cost_code_name === 'N/A' || !entry.cost_code || !entry.cost_code.name || totalRevenue === 0;

            const row = worksheet.addRow([
                current_row === startingRow ? currentDate.toISOString().split('T')[0] : "",
                cost_code_name,
                quantity,
                rate,
                {
                    formula: `C${current_row} * D${current_row}`
                }
            ]);
            
            // Apply red fill to rows with warnings
            if (hasWarning) {
              // Apply light red background to the entire row
              row.eachCell({ includeEmpty: true }, function(cell) {
                cell.fill = {
                  type: 'pattern',
                  pattern: 'solid',
                  fgColor: { argb: 'FFFFD6D6' } // Light red
                };
              });
            }
        });
        const endingRow = worksheet.lastRow.number;
        const totalRows = worksheet.addRow(["", "","", "Day Total:", { formula: `=SUM(E${startingRow}:E${endingRow})`}]);
        totals.push(totalRows.number);

        generateWhiteSpace(worksheet);
        currentDate.setDate(currentDate.getDate() + 1);
    }

    let formula = "=";
    totals.forEach((row) => { formula += `E${row} + `; });
    worksheet.addRow([
        "", "", "", "Quantities Total:",
        { formula: formula.slice(0, -2) }
    ]);

    generateWhiteSpace(worksheet);
}

/**
 * Generate equipment data for Excel
 */
function generateEquipmentData(worksheet, equipmentData, start_date, end_date) {
    generateEquipmentHeader(worksheet);
    generateEquipmentColumns(worksheet);
    var currentDate = new Date(start_date);
    var totals = [];

    while (currentDate <= end_date) {
        const currentDateString = currentDate.toISOString().split('T')[0];
        const currDateEquipment = equipmentData.filter(entry => entry.date === currentDateString);

        if (currDateEquipment.length === 0) {
            currentDate.setDate(currentDate.getDate() + 1);
            continue;
        }

        let startingRow = worksheet.lastRow.number + 1;
        currDateEquipment.forEach((entry) => {
            let current_row = worksheet.lastRow.number + 1;
            let cost_code_name = entry.cost_code?.name || 'N/A';
            const normalizedCostCodeMap = {};
            for (let key in cost_code_map) {
                const normalizedKey = normalizeString(key);
                normalizedCostCodeMap[normalizedKey] = cost_code_map[key];
            }
            // Use $0 rate if cost code is missing or not found in the map (instead of 65)
            const normalizedCostCode = normalizeString(cost_code_name);
            const rate = (normalizedCostCode && cost_code_name !== 'N/A') ? normalizedCostCodeMap[normalizedCostCode] || 0 : 0;
            
            const quantity = extractNumberFromString(entry.notes || '') || 1;
            const totalRevenue = quantity * rate;
            
            // Check if this row has a warning condition (missing cost code or zero revenue)
            const hasWarning = cost_code_name === 'N/A' || !entry.cost_code || !entry.cost_code.name || totalRevenue === 0;

            const row = worksheet.addRow([
                current_row === startingRow ? currentDate.toISOString().split('T')[0] : "",
                cost_code_name,
                quantity,
                rate,
                {
                    formula: `C${current_row} * D${current_row}`
                }
            ]);
            
            // Apply red fill to rows with warnings
            if (hasWarning) {
              // Apply light red background to the entire row
              row.eachCell({ includeEmpty: true }, function(cell) {
                cell.fill = {
                  type: 'pattern',
                  pattern: 'solid',
                  fgColor: { argb: 'FFFFD6D6' } // Light red
                };
              });
            }      
        });
        const endingRow = worksheet.lastRow.number;
        const totalRows = worksheet.addRow(["", "","", "Day Total:", { formula: `=SUM(E${startingRow}:E${endingRow})`}]);
        totals.push(totalRows.number);

        generateWhiteSpace(worksheet);
        currentDate.setDate(currentDate.getDate() + 1);
    }

    let formula = "=";
    totals.forEach((row) => { formula += `E${row} + `; });
    worksheet.addRow([
        "", "", "", "Equipment Total:",
        { formula: formula.slice(0, -2) }
    ]);

    generateWhiteSpace(worksheet);
}

/**
 * Prepare labor data for PDF with improved text truncation for long names
 */
function prepareLaborDataForPdf(timecards, startDate, endDate) {
    const result = { byDate: {}, flatRows: [], totals: {} };
    let currentDate = new Date(startDate);
    
    while (currentDate <= endDate) {
        const currentDateString = currentDate.toISOString().split('T')[0];
        const currDateTimecards = timecards.filter(entry => entry.date === currentDateString);
        
        if (currDateTimecards.length === 0) {
            currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1));
            continue;
        }
        
        let dailyTotal = 0;
        const formattedDate = formatDateForDisplay(currentDate);
        
        currDateTimecards.forEach((entry, index) => {
            // Get hours with fallback logic for high hours or missing time entries
            let regularHours, overtimeHours, premiumHours, premiumOvertime, totalHours;
            
            if (!entry.time_in || !entry.time_out) {
              // If time_in or time_out missing, try to use metadata hours
              regularHours = entry.metadata?.raw_data?.hours ? Number(entry.metadata.raw_data.hours) : 0;
              overtimeHours = 0;
              premiumHours = 0;
              premiumOvertime = 0;
              totalHours = regularHours;
            } else {
              // Calculate hours normally
              const hours = calculateHours(entry.time_in, entry.time_out);
              regularHours = hours.regular;
              overtimeHours = hours.overtime;
              premiumHours = hours.premium;
              premiumOvertime = hours.premiumOvertime;
              totalHours = regularHours + overtimeHours + premiumHours + premiumOvertime;
              
              // If total hours exceeds 22, fallback to metadata hours
              if (totalHours > 22) {
                regularHours = entry.metadata?.raw_data?.hours ? Number(entry.metadata.raw_data.hours) : 0;
                overtimeHours = 0;
                premiumHours = 0;
                premiumOvertime = 0;
                totalHours = regularHours;
              }
            }
            
            const cost_code_name = entry.cost_code?.name || 'N/A';
            const normalizedCostCodeMap = {};
            for (let key in cost_code_map) {
                const normalizedKey = normalizeString(key);
                normalizedCostCodeMap[normalizedKey] = cost_code_map[key];
            }
            
            // Use $0 rate if cost code is missing or not found in the map (instead of 50)
            const normalizedCostCode = normalizeString(cost_code_name);
            const rate = (normalizedCostCode && cost_code_name !== 'N/A') ? normalizedCostCodeMap[normalizedCostCode] || 0 : 0;
            
            const totalCharge = (regularHours * rate) + 
                               (overtimeHours * 1.5 * rate) + 
                               (premiumHours * 2.25 * rate) + 
                               (premiumOvertime * 3.375 * rate);
            
            dailyTotal += totalCharge;
            
            // Try different possible field names for employee data instead of just entry.party?.name
            const employeeName = 
              (entry.metadata?.raw_data?.party?.name) ||
              (entry.user?.name) || 
              (entry.employee?.name) || 
              (entry.labor_entry_user?.name) ||
              (entry.created_by?.name) ||
              (entry.party?.name) ||
              'N/A';
            
            // Truncate long names to fit in the table
            const truncatedName = employeeName.length > 15 ? employeeName.substring(0, 15) + '...' : employeeName;
            const truncatedTitle = cost_code_name.length > 12 ? cost_code_name.substring(0, 12) + '...' : cost_code_name;
            
            // Check if this row has a warning condition (missing cost code or zero revenue)
            const hasWarning = cost_code_name === 'N/A' || !entry.cost_code || !entry.cost_code.name || totalCharge === 0;
            
            // If there's a warning, add styling for PDF
            const rowStyle = hasWarning ? { fillColor: '#FFD6D6' } : {}; // Light red background for rows with warnings
            
            // Add to flat rows for PDF table
            result.flatRows.push([
                { text: index === 0 ? formattedDate : '', ...rowStyle },
                { text: truncatedName, ...rowStyle },
                { text: truncatedTitle, ...rowStyle },
                { text: formatTime(entry.time_in) || 'N/A', ...rowStyle },
                { text: formatTime(entry.time_out) || 'N/A', ...rowStyle },
                { text: regularHours.toString(), ...rowStyle },
                { text: `$${rate.toFixed(2)}`, ...rowStyle },
                { text: overtimeHours.toString(), ...rowStyle },
                { text: premiumHours.toString(), ...rowStyle },
                { text: premiumOvertime.toString(), ...rowStyle },
                { text: `$${totalCharge.toFixed(2)}`, ...rowStyle }
            ]);
        });
        
        // Add daily total row
        result.flatRows.push([
            '', '', '', '', '', '', '', '', '', 'Total:',
            { text: `$${dailyTotal.toFixed(2)}`, bold: true }
        ]);
        
        // Add empty row for spacing
        result.flatRows.push(Array(11).fill(''));
        
        // Save daily total
        result.byDate[currentDateString] = {
            entries: currDateTimecards,
            total: dailyTotal
        };
        
        currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1));
    }
    
    return result;
}

/**
 * Prepare quantities data for PDF
 */
function prepareQuantitiesDataForPdf(quantitiesData, startDate, endDate) {
    const result = { byDate: {}, flatRows: [], totals: {} };
    let currentDate = new Date(startDate);
    
    while (currentDate <= endDate) {
        const currentDateString = currentDate.toISOString().split('T')[0];
        const currDateQuantities = quantitiesData.filter(entry => entry.date === currentDateString);
        
        if (currDateQuantities.length === 0) {
            currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1));
            continue;
        }
        
        let dailyTotal = 0;
        const formattedDate = formatDateForDisplay(currentDate);
        
        currDateQuantities.forEach((entry, index) => {
            const cost_code_name = entry.cost_code?.name || 'N/A';
            const normalizedCostCodeMap = {};
            for (let key in cost_code_map) {
                const normalizedKey = normalizeString(key);
                normalizedCostCodeMap[normalizedKey] = cost_code_map[key];
            }
            
            // Use $0 rate if cost code is missing or not found in the map (instead of 65)
            const normalizedCostCode = normalizeString(cost_code_name);
            const rate = (normalizedCostCode && cost_code_name !== 'N/A') ? normalizedCostCodeMap[normalizedCostCode] || 0 : 0;
            
            const quantity = entry.quantity || 0;
            const total = quantity * rate;
            
            dailyTotal += total;
            
            // Check if this row has a warning condition (missing cost code or zero revenue)
            const hasWarning = cost_code_name === 'N/A' || !entry.cost_code || !entry.cost_code.name || total === 0;
            
            // If there's a warning, add styling for PDF
            const rowStyle = hasWarning ? { fillColor: '#FFD6D6' } : {}; // Light red background for rows with warnings
            
            // Add to flat rows for PDF table
            result.flatRows.push([
                { text: index === 0 ? formattedDate : '', ...rowStyle },
                { text: cost_code_name, ...rowStyle },
                { text: quantity.toString(), ...rowStyle },
                { text: `$${rate.toFixed(2)}`, ...rowStyle },
                { text: `$${total.toFixed(2)}`, ...rowStyle }
            ]);
        });
        
        // Add daily total row
        result.flatRows.push([
            '', '', '', 'Day Total:',
            { text: `$${dailyTotal.toFixed(2)}`, bold: true }
        ]);
        
        // Add empty row for spacing
        result.flatRows.push(Array(5).fill(''));
        
        // Save daily total
        result.byDate[currentDateString] = {
            entries: currDateQuantities,
            total: dailyTotal
        };
        
        currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1));
    }
    
    return result;
}

/**
 * Prepare equipment data for PDF
 */
function prepareEquipmentDataForPdf(equipmentData, startDate, endDate) {
    const result = { byDate: {}, flatRows: [], totals: {} };
    let currentDate = new Date(startDate);
    
    while (currentDate <= endDate) {
        const currentDateString = currentDate.toISOString().split('T')[0];
        const currDateEquipment = equipmentData.filter(entry => entry.date === currentDateString);
        
        if (currDateEquipment.length === 0) {
            currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1));
            continue;
        }
        
        let dailyTotal = 0;
        const formattedDate = formatDateForDisplay(currentDate);
        
        currDateEquipment.forEach((entry, index) => {
            const cost_code_name = entry.cost_code?.name || 'N/A';
            const normalizedCostCodeMap = {};
            for (let key in cost_code_map) {
                const normalizedKey = normalizeString(key);
                normalizedCostCodeMap[normalizedKey] = cost_code_map[key];
            }
            
            // Use $0 rate if cost code is missing or not found in the map (instead of 65)
            const normalizedCostCode = normalizeString(cost_code_name);
            const rate = (normalizedCostCode && cost_code_name !== 'N/A') ? normalizedCostCodeMap[normalizedCostCode] || 0 : 0;
            
            const quantity = extractNumberFromString(entry.notes || '') || 1;
            const total = quantity * rate;
            
            dailyTotal += total;
            
            // Check if this row has a warning condition (missing cost code or zero revenue)
            const hasWarning = cost_code_name === 'N/A' || !entry.cost_code || !entry.cost_code.name || total === 0;
            
            // If there's a warning, add styling for PDF
            const rowStyle = hasWarning ? { fillColor: '#FFD6D6' } : {}; // Light red background for rows with warnings
            
            // Add to flat rows for PDF table
            result.flatRows.push([
                { text: index === 0 ? formattedDate : '', ...rowStyle },
                { text: cost_code_name, ...rowStyle },
                { text: quantity.toString(), ...rowStyle },
                { text: `$${rate.toFixed(2)}`, ...rowStyle },
                { text: `$${total.toFixed(2)}`, ...rowStyle }
            ]);
        });
        
        // Add daily total row
        result.flatRows.push([
            '', '', '', 'Day Total:',
            { text: `$${dailyTotal.toFixed(2)}`, bold: true }
        ]);
        
        // Add empty row for spacing
        result.flatRows.push(Array(5).fill(''));
        
        // Save daily total
        result.byDate[currentDateString] = {
            entries: currDateEquipment,
            total: dailyTotal
        };
        
        currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1));
    }
    
    return result;
}

/**
 * Calculate labor total from prepared data
 */
function calculateLaborTotal(laborData) {
    let total = 0;
    Object.values(laborData.byDate).forEach(dayData => {
        total += dayData.total;
    });
    return total;
}

/**
 * Calculate quantities total from prepared data
 */
function calculateQuantitiesTotal(quantitiesData) {
    let total = 0;
    Object.values(quantitiesData.byDate).forEach(dayData => {
        total += dayData.total;
    });
    return total;
}

/**
 * Calculate equipment total from prepared data
 */
function calculateEquipmentTotal(equipmentData) {
    let total = 0;
    Object.values(equipmentData.byDate).forEach(dayData => {
        total += dayData.total;
    });
    return total;
}

/**
 * Create PDF table with custom formatting and improved layout
 */
function createPdfTable(headers, rows, options = {}) {
    // Default table styling
    const tableOptions = {
        headerRows: 1,
        widths: options.widths || Array(headers.length).fill('*'),
        body: [
            // Header row
            headers.map(header => ({
                text: header, 
                bold: true, 
                fillColor: '#123286', 
                color: 'white',
                alignment: 'center',
                fontSize: options.fontSize || 9 // Use provided font size or default to 9
            })),
            // Data rows
            ...rows.map(row => 
                row.map(cell => {
                    if (typeof cell === 'object') {
                        return {
                            ...cell,
                            fontSize: options.fontSize || 9 // Apply font size to objects
                        };
                    }
                    return {
                        text: cell,
                        fontSize: options.fontSize || 9 // Apply font size to text cells
                    };
                })
            )
        ]
    };
    
    return {
        table: tableOptions,
        layout: {
            hLineWidth: function(i, node) { return 0.5; },
            vLineWidth: function(i, node) { return 0.5; },
            hLineColor: function(i, node) { return '#dddddd'; },
            vLineColor: function(i, node) { return '#dddddd'; },
            paddingLeft: function(i, node) { return 3; }, // Reduced padding
            paddingRight: function(i, node) { return 3; }, // Reduced padding
            paddingTop: function(i, node) { return 3; }, // Reduced padding
            paddingBottom: function(i, node) { return 3; }, // Reduced padding
            fillColor: function(rowIndex, node, columnIndex) {
                return (rowIndex % 2 === 0) ? '#f9f9f9' : null;
            }
        }
    };
}

/**
 * Generate Excel invoice
 */
async function generateExcelInvoice(timecards, quantitiesRawData, equipmentData, startDate, endDate, filename) {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Daily Logs');

    generateLaborData(worksheet, timecards, startDate, endDate);
    generateQuantitiesData(worksheet, quantitiesRawData, startDate, endDate);
    generateEquipmentData(worksheet, equipmentData, startDate, endDate);

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `${filename}.xlsx`;
    link.click();

    URL.revokeObjectURL(link.href);
}

/**
 * Generate PDF invoice with fixed layout and formatting
 */
async function generatePdfInvoice(timecards, quantitiesRawData, equipmentData, startDate, endDate, filename, projectId) {
    // Ensure pdfMake is properly initialized
    if (!pdfMake) {
        console.error('pdfMake is not available');
        throw new Error('PDF generation library not available');
    }

    // Convert logo to base64 if it's a URL
    let logoDataUrl;
    try {
        // Try to convert the image URL to base64
        logoDataUrl = await getBase64FromImageUrl(logoUrl);
    } catch (error) {
        console.error('Error loading logo:', error);
        // Use a simple text as fallback if image loading fails
        logoDataUrl = null;
    }

    // Prepare data for PDF
    const laborData = prepareLaborDataForPdf(timecards, startDate, endDate);
    const quantitiesData = prepareQuantitiesDataForPdf(quantitiesRawData, startDate, endDate);
    const equipmentDataPdf = prepareEquipmentDataForPdf(equipmentData, startDate, endDate);
    
    // Calculate totals
    const laborTotal = calculateLaborTotal(laborData);
    const quantitiesTotal = calculateQuantitiesTotal(quantitiesData);
    const equipmentTotal = calculateEquipmentTotal(equipmentDataPdf);
    const grandTotal = laborTotal + quantitiesTotal + equipmentTotal;

    // Format dates for display
    const formattedStartDate = formatDateForDisplay(startDate);
    const formattedEndDate = formatDateForDisplay(endDate);
    
    // Create header with fixed logo size
    const headerColumns = [];
    if (logoDataUrl) {
        headerColumns.push({
            image: logoDataUrl,
            width: 50, // Reduced logo size
            margin: [40, 10, 40, 10] // Adjusted margins
        });
    } else {
        headerColumns.push({
            text: 'RESTORE FAST',
            fontSize: 20,
            bold: true,
            margin: [40, 20, 0, 0],
            color: '#f48d56'
        });
    }
    // Add title to header
    headerColumns.push({
        text: 'DAILY LOG SUMMARY',
        alignment: 'right', 
        fontSize: 16,
        bold: true,
        margin: [0, 20, 40, 0], // Adjusted margin
        color: '#f48d56'
    });


    
    // Create PDF document definition with improved layout
    const docDefinition = {
        pageSize: 'LETTER',
        pageMargins: [20, 80, 20, 60], // Reduced margins to give more space for content
        watermark: { text: 'RestoreFast', color: '#f48d5622', opacity: 0.1, bold: true, fontSize: 60 },
        
        header: {
            columns: headerColumns,
            margin: [20, 20, 20, 20] // Adjusted header margins
        },
        
        footer: function(currentPage, pageCount) {
            return {
                columns: [
                    { 
                        text: `Generated on ${new Date().toLocaleDateString()}`,
                        alignment: 'left',
                        margin: [40, 0, 0, 0],
                        fontSize: 8,
                        color: 'grey'
                    },
                    { 
                        text: `Page ${currentPage} of ${pageCount}`,
                        alignment: 'right',
                        margin: [0, 0, 40, 0],
                        fontSize: 8,
                        color: 'grey'
                    }
                ]
            };
        },
        
        content: [
            // Invoice info section
            {
                style: 'invoiceInfo',
                columns: [
                    [
                        { text: 'Project ID:', bold: true },
                        { text: 'Invoice Period:', bold: true },
                        { text: 'Generated:', bold: true }
                    ],
                    [
                        { text: projectId },
                        { text: `${formattedStartDate} - ${formattedEndDate}` },
                        { text: new Date().toLocaleDateString() }
                    ]
                ]
            },
            
            // Labor section
            {
                stack: [
                    {
                        text: 'Labor',
                        style: 'sectionHeader',
                        margin: [0, 20, 0, 10]
                    },
                    createPdfTable(
                        ['Date', 'Name', 'Title', 'Time-In', 'Time-Out', 'Reg Hours', 'Rate', 'OT Hrs', '2X OT', 'Prem 2X', 'Total'], // Shortened headers
                        laborData.flatRows,
                        { 
                            widths: [40, '*', 60, 40, 40, 25, 40, 25, 25, 25, 50], // Adjusted column widths
                            fontSize: 8 // Smaller font size
                        }
                    ),
                    {
                        text: `Labor Total: $${laborTotal.toFixed(2)}`,
                        alignment: 'right',
                        bold: true,
                        margin: [0, 10, 0, 20]
                    }
                ]
            },
            
            // Quantities section
            {
                stack: [
                    {
                        text: 'Quantities',
                        style: 'sectionHeader',
                        margin: [0, 10, 0, 10]
                    },
                    createPdfTable(
                        ['Date', 'Cost Code', 'Quantity', 'Rate', 'Total'],
                        quantitiesData.flatRows,
                        { 
                            widths: [60, '*', 50, 50, 60],
                            fontSize: 8 // Smaller font size
                        }
                    ),
                    {
                        text: `Quantities Total: $${quantitiesTotal.toFixed(2)}`,
                        alignment: 'right',
                        bold: true,
                        margin: [0, 10, 0, 20]
                    }
                ]
            },
            
            // Equipment section
            {
                stack: [
                    {
                        text: 'Equipment',
                        style: 'sectionHeader',
                        margin: [0, 10, 0, 10]
                    },
                    createPdfTable(
                        ['Date', 'Cost Code', 'Quantity', 'Rate', 'Total'],
                        equipmentDataPdf.flatRows,
                        { 
                            widths: [60, '*', 50, 50, 60],
                            fontSize: 8 // Smaller font size
                        }
                    ),
                    {
                        text: `Equipment Total: $${equipmentTotal.toFixed(2)}`,
                        alignment: 'right',
                        bold: true,
                        margin: [0, 10, 0, 0]
                    }
                ]
            },
            
            // Grand total
            {
                text: `Grand Total: $${grandTotal.toFixed(2)}`,
                style: 'grandTotal',
                margin: [0, 30, 0, 20]
            }
        ],
        
        styles: {
            sectionHeader: {
                fontSize: 14,
                bold: true,
                color: '#123286',
                fillColor: '#f48d5622',
                padding: [8, 4, 8, 4]
            },
            invoiceInfo: {
                margin: [0, 20, 0, 20],
                columnGap: 10
            },
            grandTotal: {
                fontSize: 16,
                bold: true,
                alignment: 'right',
                color: '#123286'
            }
        }
    };
    
    // Generate PDF and download
    const pdfDoc = pdfMake.createPdf(docDefinition);
    pdfDoc.download(`${filename}.pdf`);
}


/**
 * Generate invoice in the selected format (Excel or PDF)
 * @param {Date} startDate - The start date for the invoice period
 * @param {Date} endDate - The end date for the invoice period
 * @param {string} projectId - The project ID
 * @param {string} format - The output format ('excel' or 'pdf')
 * @param {string} accessToken - Optional access token for API calls
 * @returns {Object} - Object containing any warnings about missing cost codes
 */
export async function generateInvoice(startDate, endDate, projectId, format = 'excel', accessToken) {
    const filename = `project_${projectId}_${startDate.toISOString().split('T')[0]}_${endDate.toISOString().split('T')[0]}`;
    console.log(`Generating ${format} invoice with filename: ${filename}`);

    const startDateString = startDate.toISOString().split('T')[0];
    const endDateString = endDate.toISOString().split('T')[0];

    try {
        // Fetch data from API
        const timecardsResponse = await getTimecardList(projectId, startDateString, endDateString, accessToken);
        const quantitiesResponse = await getQuantitiesList(projectId, startDateString, endDateString, accessToken);
        const equipmentResponse = await getEquipmentList(projectId, startDateString, endDateString, accessToken);

        if (!timecardsResponse || !quantitiesResponse || !equipmentResponse) {
            throw new Error("Failed to fetch data from the API");
        }

        const timecards = timecardsResponse.data;
        const quantitiesRawData = quantitiesResponse.data;
        const equipmentData = equipmentResponse.data;

        if (!timecards || !quantitiesRawData || !equipmentData) {
            throw new Error("One of the API responses is empty or invalid");
        }

        // Check for missing cost codes before generating the document
        const missingCostCodes = {
            timecards: [],
            quantities: [],
            equipment: []
        };

        // Check timecards
        timecards.forEach(entry => {
            if (!entry.cost_code || !entry.cost_code.name) {
                // Use the same employee name logic for consistency
                const employeeName = 
                  (entry.metadata?.raw_data?.party?.name) ||
                  (entry.user?.name) || 
                  (entry.employee?.name) || 
                  (entry.labor_entry_user?.name) ||
                  (entry.created_by?.name) ||
                  (entry.party?.name) ||
                  'Unknown';
                
                missingCostCodes.timecards.push({
                    id: entry.id,
                    date: entry.date,
                    name: employeeName,
                    time_in: entry.time_in,
                    time_out: entry.time_out
                });
            }
        });

        // Check quantities
        quantitiesRawData.forEach(entry => {
            if (!entry.cost_code || !entry.cost_code.name) {
                missingCostCodes.quantities.push({
                    id: entry.id,
                    date: entry.date,
                    description: entry.description || 'No description',
                    quantity: entry.quantity
                });
            }
        });

        // Check equipment
        equipmentData.forEach(entry => {
            if (!entry.cost_code || !entry.cost_code.name) {
                missingCostCodes.equipment.push({
                    id: entry.id,
                    date: entry.date,
                    description: entry.description || 'No description',
                    notes: entry.notes
                });
            }
        });

        // Generate the appropriate format
        if (format.toLowerCase() === 'pdf') {
            await generatePdfInvoice(timecards, quantitiesRawData, equipmentData, startDate, endDate, filename, projectId);
        } else {
            await generateExcelInvoice(timecards, quantitiesRawData, equipmentData, startDate, endDate, filename);
        }

        // Return the missing cost codes so the UI can display them
        return {
            success: true,
            missingCostCodes,
            hasMissingCostCodes: 
                missingCostCodes.timecards.length > 0 || 
                missingCostCodes.quantities.length > 0 || 
                missingCostCodes.equipment.length > 0
        };
    } catch (error) {
        console.error("Error generating invoice:", error);
        throw error; // Re-throw to allow the UI to handle the error
    }
}